import { ReactElement } from 'react'

import Icon, { IconNames } from 'src/components/blocks/icons/'
import './style.scss'

interface Props {
  timer: ReactElement
  iconSize: number
}

const Time = ({ timer, iconSize }: Props) => {
  return (
    <div className="time">
      <div>
        <Icon name={IconNames.CLOCK} size={iconSize} />
      </div>
      &nbsp;
      {timer}
    </div>
  )
}

export default Time
