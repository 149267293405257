const appUrlByEnvironment = () => {
  switch (process.env.REACT_APP_API_URL) {
    case 'api.develop.nextroundboxing.com':
      return 'https://app.develop.nextroundboxing.com'
    case 'api.staging.nextroundboxing.com':
      return 'https://app.staging.nextroundboxing.com'
    default:
      return 'https://app.develop.nextroundboxing.com'
  }
}

export default appUrlByEnvironment
