import { useState } from 'react'

import NotificationSetting from 'src/components/blocks/notification-setting'
import './style.scss'

// The buddies feature is not implemented yet
// Users can currently select their notification settings through the "more" page, but the settings are not saved
const Notifications = () => {
  const [notificationSettings, setNotificationSettings] = useState([
    { name: 'Notifications', on: true },
    { name: 'App updates', on: true },
    { name: 'Workout reminders', on: true },
    { name: 'New session results', on: true },
    { name: 'Followers request', on: true },
  ])

  const notificationSetter = (singleSetting: string) => {
    if (singleSetting === 'Notifications') {
      const globalSettings = notificationSettings[0].on
      setNotificationSettings(
        notificationSettings.map((setting) => {
          return { ...setting, on: !globalSettings }
        })
      )
    } else {
      setNotificationSettings(
        notificationSettings.map((setting) => {
          if (singleSetting === setting.name) {
            return { ...setting, on: !setting.on }
          } else {
            return { ...setting }
          }
        })
      )
    }
  }

  return (
    <div className="notifications">
      {notificationSettings.map(({ name, on }, i) => {
        return (
          <NotificationSetting
            key={i}
            name={name}
            on={on}
            notificationSetter={notificationSetter}
          />
        )
      })}
    </div>
  )
}

export default Notifications
