import React from 'react'
import './style.scss'

import activity from 'src/assets/icons/activity.svg'
import addBuddy from 'src/assets/icons/add_buddy.svg'
import arrowDown from 'src/assets/icons/arrow_down.svg'
import arrowLeft from 'src/assets/icons/arrow_left.svg'
import arrowRight from 'src/assets/icons/arrow_right.svg'
import arrowUp from 'src/assets/icons/arrow_up.svg'
import boxbag from 'src/assets/icons/boxbag.svg'
import boxhand from 'src/assets/icons/box_fist.svg'
import calories from 'src/assets/icons/calories.svg'
import check from 'src/assets/icons/check.svg'
import clap from 'src/assets/icons/clap.svg'
import clapped from 'src/assets/icons/clapped.svg'
import claps from 'src/assets/icons/claps.svg'
import clock from 'src/assets/icons/clock.svg'
import cross from 'src/assets/icons/cross.svg'
import duration from 'src/assets/icons/duration.svg'
import eye from 'src/assets/icons/eye.svg'
import facebook from 'src/assets/icons/facebook.svg'
import force from 'src/assets/icons/force.svg'
import frequency from 'src/assets/icons/frequency.svg'
import info from 'src/assets/icons/info.svg'
import instagram from 'src/assets/icons/instagram.svg'
import logoBig from 'src/assets/icons/logo_big.svg'
import logoSmall from 'src/assets/icons/logo_small.svg'
import minus from 'src/assets/icons/minus.svg'
import more from 'src/assets/icons/more.svg'
import mute from 'src/assets/icons/mute.svg'
import note from 'src/assets/icons/note.svg'
import notification from 'src/assets/icons/notification.svg'
import pause from 'src/assets/icons/pause.svg'
import play from 'src/assets/icons/play.svg'
import plus from 'src/assets/icons/plus.svg'
import profileIcon from 'src/assets/icons/profile_icon.svg'
import profilePic from 'src/assets/icons/profile_pic1.svg'
import punches from 'src/assets/icons/punches.svg'
import rounds from 'src/assets/icons/rounds.svg'
import share from 'src/assets/icons/share.svg'
import shareOrange from 'src/assets/icons/share_orange.svg'
import shareLink from 'src/assets/icons/share_link.svg'
import speaker from 'src/assets/icons/speaker.svg'
import twitter from 'src/assets/icons/twitter.svg'
import firstPlace from 'src/assets/icons/first_place.svg'
import secondPlace from 'src/assets/icons/second_place.svg'
import thirdPlace from 'src/assets/icons/third_place.svg'

export enum IconNames {
  ACTIVITY = 'activity',
  ADD_BUDDY = 'add-buddy',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  ARROW_UP = 'arrow-up',
  BOXBAG = 'boxbag',
  BOXHAND = 'boxhand',
  CALORIES = 'calories',
  CHECK = 'check',
  CLAP = 'clap',
  CLAPPED = 'clapped',
  CLAPS = 'claps',
  CLOCK = 'clock',
  CROSS = 'cross',
  DURATION = 'duration',
  EYE = 'eye',
  FACEBOOK = 'facebook',
  FORCE = 'force',
  FREQUENCY = 'frequency',
  INFO = 'info',
  INSTAGRAM = 'instagram',
  LOGO_BIG = 'logo-big',
  LOGO_SMALL = 'logo-small',
  MINUS = 'minus',
  MORE = 'more',
  MUTE = 'mute',
  NOTE = 'note',
  NOTIFICATION = 'notification',
  PAUSE = 'pause',
  PLAY = 'play',
  PLUS = 'plus',
  PROFILE_ICON = 'profile-icon',
  PROFILE_PIC = 'profile-pic',
  PUNCHES = 'punches',
  ROUNDS = 'rounds',
  SHARE = 'share',
  SHARE_ORANGE = 'share-orange',
  SHARE_LINK = 'share-link',
  SPEAKER = 'speaker',
  TWITTER = 'twitter',
  FIRST_PLACE = 'first-place',
  SECOND_PLACE = 'second-place',
  THIRD_PLACE = 'third-place',
}

interface IconProps {
  name: IconNames
  size?: number
  color?: null | string
  customClickEvent?: () => void
}

const initialIcon: IconProps = {
  name: IconNames.SPEAKER,
  size: 24,
  color: null,
  customClickEvent: () => {},
}

const icons: { [key in IconNames]: string } = {
  activity,
  'add-buddy': addBuddy,
  'arrow-down': arrowDown,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  boxbag,
  boxhand,
  calories,
  check,
  clap,
  clapped,
  claps,
  clock,
  cross,
  duration,
  facebook,
  force,
  frequency,
  eye,
  info,
  instagram,
  'logo-big': logoBig,
  'logo-small': logoSmall,
  minus,
  more,
  mute,
  note,
  notification,
  pause,
  plus,
  play,
  'profile-icon': profileIcon,
  'profile-pic': profilePic,
  punches,
  rounds,
  share,
  'share-orange': shareOrange,
  'share-link': shareLink,
  speaker,
  twitter,
  'first-place': firstPlace,
  'second-place': secondPlace,
  'third-place': thirdPlace,
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const color: string = props.color || ''
  return (
    <div
      onClick={props.customClickEvent}
      className={'icon ' + color}
      style={{ height: props.size, width: props.size }}
    >
      <img alt="icon" src={icons[props.name]} />
    </div>
  )
}

Icon.defaultProps = initialIcon

export default Icon
