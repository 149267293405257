import {
  INRWorkoutHit,
  INRWorkoutTemplate,
  INRExercise,
  INRWorkout,
  INRWorkoutSessionTime,
} from '../types/workouts'

// These functions should be aligned with the training app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

export const calcPercentage = ({
  partialValue,
  totalValue,
}: {
  partialValue: number
  totalValue: number
}) => (100 * partialValue) / totalValue

export const getExercisesFromWorkout = (workout: INRWorkout): INRExercise[] =>
  workout.rounds.flatMap(({ exercises }) => exercises)

export const getWorkoutTemplateDuration = (
  workoutTemplate: INRWorkoutTemplate
): string => {
  const duration = !!workoutTemplate.duration
    ? workoutTemplate.duration
    : getSummedUpWorkoutTemplateDuration(workoutTemplate)
  return toRoundedFullTime(duration)
}

export const getSummedUpWorkoutTemplateDuration = (
  workoutTemplate: INRWorkoutTemplate
): number =>
  sumUp(
    workoutTemplate.variants[0].rounds.flatMap(({ exercises }) =>
      exercises.flatMap(({ seconds }) => seconds)
    )
  )

export const sumUp = (array: number[]): number =>
  array.reduce((acc, cur) => acc + cur, 0)

export const getAverage = (array: number[]): number =>
  array.reduce((a, b) => a + b, 0) / array.length

export const getMax2Decimal = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100

export const toFullTime = (timeInSeconds: number): string => {
  const hours = String(Math.floor(timeInSeconds / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((timeInSeconds % 3600) / 60)).padStart(
    2,
    '0'
  )
  const seconds = String(timeInSeconds % 60).padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

export const toRoundedFullTime = (timeInSeconds: number): string => {
  const roundedTimeInSeconds = Math.round(timeInSeconds / 30) * 30
  return toFullTime(roundedTimeInSeconds)
}

export const compareDate = (createdAt: string) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours * minutes * seconds * milliseconds
  const currentDate = new Date()
  const createdDate = new Date(Number(BigInt(createdAt)))
  const timestampDiff = currentDate.getTime() - createdDate.getTime()
  const diffDays = Math.round(Math.abs(timestampDiff / oneDay))

  return diffDays
}

export const compressDateString = (dateAsString: string) =>
  new Date(Number(BigInt(dateAsString)))

export const getRoundedTimeUnitsFromDate = (dateAsString: string) => {
  const date = compressDateString(dateAsString)

  const minutes = String(date.getMinutes()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return { hours, minutes, seconds }
}

export const convertHitsToTimedHits = (
  hits: INRWorkoutHit[],
  started: Date | null
): INRWorkoutSessionTime => {
  const timedHits: INRWorkoutSessionTime = {}

  hits.forEach((hit) => {
    const hitTimeInSeconds = Math.floor(
      calculateHitTimeInSeconds(hit.punchedAt, started)
    ) // Ensure integer key

    if (!timedHits[hitTimeInSeconds]) {
      timedHits[hitTimeInSeconds] = []
    }

    timedHits[hitTimeInSeconds].push({ ...hit })
  })

  return timedHits
}

function calculateHitTimeInSeconds(
  punchedAt: string,
  started: Date | null
): number {
  // Assuming punchedAt is a string representing a timestamp in milliseconds
  const hitTimeInMilliseconds = parseInt(punchedAt)
  // If started time is provided, calculate offset
  if (started) {
    const startTimeInMilliseconds = new Date(started).getTime()
    return (hitTimeInMilliseconds - startTimeInMilliseconds) / 1000 // Convert to seconds
  } else {
    // If started time is not available, use raw timestamp as seconds
    return hitTimeInMilliseconds / 1000
  }
}

// In the beginning of june, we deployed a updated, and correct, calculation for graphs and intensity. 
// However, we have a lot of workouts that were created before this date, 
// and we need to keep the old calculations for those workouts. 
// This function is used to check if a workout was created before june 2024.
export const workoutIsBeforeJune2024 = (createdAt: string) => {
  const date = new Date(Number(BigInt(createdAt)))
  return date < new Date('2024-06-01')
}

// Old functions to help calculate intensity for old workouts
export const convertHitsToSec = (
  hits: INRWorkoutHit[] = []
): INRWorkoutSessionTime => {
  const groupedHits: INRWorkoutSessionTime = {}

  for (let i = 0; i < hits.length; i++) {
    const hit = hits[i]
    const punchedAt = parseInt(hit.punchedAt)
    const second = Math.floor(punchedAt / 1000)

    if (!groupedHits[second]) {
      groupedHits[second] = []
    } else {
      groupedHits[second].push(hit)
    }
  }

  const transformedObject: { [key: number]: any[] } = Object.keys(
    groupedHits
  ).reduce((result, key, index) => {
    result[index as any] = groupedHits[parseInt(key)]
    return result
  }, {} as { [key: number]: any[] })

  return transformedObject
}

export const convertHitsPerSec = (
  hitsPerSecond: INRWorkoutSessionTime = []
): INRWorkoutHit[] => {
  const hits: INRWorkoutHit[] = []

  Object.keys(hitsPerSecond).forEach((second) => {
    const parsedSec = parseInt(second)
    if (!hitsPerSecond[parsedSec].length) return
    return hitsPerSecond[parsedSec].forEach((hit) => hits.push({ ...hit }))
  })
  return hits
}