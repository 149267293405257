import React, { useRef } from 'react'
import { useQuery } from '@apollo/client'

import { INRWorkout } from 'src/types/workouts'
import { getAverageForcePercent } from 'src/lib/graph'
import { getEvaluatedWorkoutIntensity } from 'src/lib/intensity'
import { calcPercentage, getAverage } from 'src/lib/utilities'
import { getMaxForce, getRoundDuration, getWorkoutHits } from 'src/lib/stats'
import SingleRoundGraph from './single-round-graph'
import CustomYAxis from './custom-y-axis'
import './style.scss'

interface Props {
  workoutData: INRWorkout
  isForceGraph: boolean
  singleRound: boolean
  index?: number
  weight: number | null
}

const Graphs = ({
  workoutData,
  isForceGraph,
  singleRound,
  index = 0,
  weight,
}: Props) => {
  const allRoundTimes = workoutData.rounds.map(getRoundDuration)

  const container = useRef() as React.MutableRefObject<HTMLInputElement>

  const scrollRight = () => {
    if (!container) return
    const element = container.current
    if (element) {
      element.scrollLeft += 20
    }
  }
  const workoutSessionHits = getWorkoutHits(workoutData)
  const maxForce = getMaxForce(workoutSessionHits)

  const avgForcePercent = getAverageForcePercent(workoutSessionHits)

  const avgIntensity = getAverage(
    getEvaluatedWorkoutIntensity(workoutData, weight ?? null)
  )

  const avgIntensityPercent = Math.round(
    calcPercentage({ partialValue: avgIntensity, totalValue: 7 })
  )

  let renderGraphs: any

  if (!singleRound) {
    renderGraphs = workoutData.rounds?.map((round, index) => {
      return (
        <div key={index} style={{ width: '100%', height: '100%' }}>
          <div className="roundsGraph__roundSeperator">
            <p>R{index + 1}</p>
            <div className="roundsGraph__roundSeperator__verticalLine" />
          </div>
          <SingleRoundGraph
            roundData={round}
            isForceGraph={isForceGraph}
            index={index}
            allRoundTimes={allRoundTimes}
            maxForce={maxForce}
            workoutCreatedAt={workoutData.createdAt ?? ''}
            weight={weight}
          />
        </div>
      )
    })
  }

  if (singleRound) {
    renderGraphs = (
      <div key={index} style={{ width: '100%', height: '100%' }}>
        <SingleRoundGraph
          roundData={workoutData.rounds[index]}
          isForceGraph={isForceGraph}
          index={index}
          allRoundTimes={allRoundTimes}
          maxForce={maxForce}
          workoutCreatedAt={workoutData.createdAt ?? ''}
          weight={weight}
        />
      </div>
    )
  }

  return (
    <div className="roundsGraph__wrapper">
      <CustomYAxis
        isForceGraph={isForceGraph}
        maxForce={maxForce}
        avg={isForceGraph ? avgForcePercent : avgIntensityPercent}
      />
      <div className="roundsGraph__graphContainer">
        {renderGraphs}
        {!singleRound && (
          <div onClick={scrollRight} className="roundsGraph__arrowRight" />
        )}
      </div>
    </div>
  )
}

export default Graphs
