import React from 'react'

import './style.scss'

const About: React.FC = () => {
  return (
    <p className="about">
      NextRound: Creating the future of sports with an award-winning intelligent
      kickboxing system. This webbased application accompanies the NextRound
      boxing bags (prototypes) that are in a testing phase. This sofware is also
      in beta: Version: <b>v1.00.00.10</b>
    </p>
  )
}

export default About
