import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import Main from 'src/components/layout/main'

interface Props {
  exact?: boolean
  path: string
  component: React.FC<any>
}

const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
  const token = localStorage.getItem('NEXTROUND_APP_TOKEN') ?? ''
  const isLoggedIn = !!token

  return (
    <Route
      {...rest}
      render={(props) => {
        return !!isLoggedIn ? (
          <Main>
            <Component {...props} />
          </Main>
        ) : (
          <Redirect to="/welcome" />
        )
      }}
    />
  )
}

export default ProtectedRoute
