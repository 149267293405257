export const headerString = (pathname: string) => {
  const path = pathname.includes('/workout') ? '/workout' : pathname.includes('/profile') ? '/profile' : pathname
  switch (path) {
    case '/buddies':
      return 'Training buddies'
    case '/follow-requests':
      return 'Follow requests'
    case '/invite':
      return 'Invite training buddies'
    case '/disclaimer':
      return 'Disclaimer'
    case '/faq':
      return 'Faq'
    case '/notifications':
      return 'Notifications'
    case '/feedback':
      return 'Feedback'
    case '/edit-profile':
      return 'Edit profile'
    case '/more':
      return 'More'
    case '/about':
      return 'About'
    case '/me':
      return 'Me'
    case '/profile':
      return 'Profile'
    case '/activity-feed':
      return 'Activity feed'
    case '/workout':
      return 'Workout details'
    default:
      return null
  }
}
