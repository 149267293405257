import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

export const newWorkoutContent = (
  <div className="new-workout-info">
    <Icon name={IconNames.LOGO_BIG} size={100} />
    <div className="new-workout-header">Great job!</div>
    <div className="new-workout-data">
      <div className="new-workout-data-col-1">
        <div className="new-workout-data-row">
          <p>Exercise</p>
          <p>
            <b>Improve Speed</b>
          </p>
        </div>
        <div className="new-workout-data-row">
          <p>Level</p>
          <p>
            <b>Starter</b>
          </p>
        </div>
        <div className="new-workout-data-row">
          <p>Time</p>
          <p>
            <b>00:20:00</b>
          </p>
        </div>
      </div>
      <div className="new-workout-data-col-2">
        <div className="new-workout-data-row">
          <p>Punches</p>
          <p>
            <b>403</b>
          </p>
        </div>
        <div className="new-workout-data-row">
          <p>Max. Force</p>
          <p>
            <b>35</b>
          </p>
        </div>
        <div className="new-workout-data-row">
          <p>Min. Force</p>
          <p>
            <b>21</b>
          </p>
        </div>
      </div>
    </div>
    <div className="new-workout-tip">
      <p>
        You were <b>10% faster</b> than the average NextRound boxer!
      </p>
      <Icon name={IconNames.CLAP} size={35} />
    </div>
    {/* <div className="new-workout-buttons">
      <Button clickHandler={tempClick} children="More results" />
      <Button clickHandler={tempClick} children="Share this workout" />
    </div> */}
  </div>
)
