import { useState } from 'react'

import Tooltip from 'src/components/blocks/tooltip'
import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

interface Props {
  text: string
  tooltip: JSX.Element
}

const SingleQuestion = ({ text, tooltip }: Props) => {
  const [displayTooltip, setDisplayTooltip] = useState<boolean>(false)

  return (
    <div
      className="single-question"
      onClick={() => {
        setDisplayTooltip(!displayTooltip)
      }}
    >
      {text}
      <div>
        <Icon name={IconNames.ARROW_DOWN} size={10} />
      </div>
      {displayTooltip && <Tooltip tooltip={tooltip} />}
    </div>
  )
}

export default SingleQuestion
