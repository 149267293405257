import { useState } from 'react'
import { useParams } from 'react-router'
import { useMutation } from '@apollo/client'

import { INRWorkout } from 'src/types/workouts'
import { UPDATE_WORKOUT_RATING } from 'src/graphql/mutations'
import Star from './star'
import './style.scss'

type Props = Pick<INRWorkout, 'user' | 'rating'> & {
  myId: string
}

const Rating = ({ rating, user, myId }: Props) => {
  const workout: any = useParams()
  const isMyWorkout = user?.id === myId

  const [updateWorkout] = useMutation(UPDATE_WORKOUT_RATING)

  const [ratingState, setRating] = useState<number>(rating ?? 0)
  const [hover, setHover] = useState<number>(0)

  const handleClick: any = (ratingValue: number) => {
    if (isMyWorkout) {
      ratingValue === rating ? setRating(0) : setRating(ratingValue)
      updateWorkout({ variables: { id: workout.id, rating: ratingState } })
    }
  }

  const onMouseEnter = (ratingValue: number) => {
    isMyWorkout && setHover(ratingValue)
  }

  const onMouseLeave = () => {
    isMyWorkout && setHover(0)
  }

  return (
    <div className="rating">
      <p className="small">
        <b>Did you like the workout?</b>
      </p>
      <div className="rating__card">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1
          const filled = ratingValue <= (hover || ratingState)
          return (
            <label key={i}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => handleClick(ratingValue)}
              />
              <Star
                filled={filled}
                ratingValue={ratingValue}
                mouseEnter={() => onMouseEnter(ratingValue)}
                mouseLeave={() => onMouseLeave}
              />
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default Rating
