import { IconNames } from 'src/components/blocks/icons'
import TopThreeBadge from './top-three-badge'
import { useQuery } from '@apollo/client'
import { LEADERBOARD_OFFSET } from 'src/graphql/queries'
import { INRLeaderboardRecord } from 'src/types/workouts'
import Loader from 'src/components/blocks/loader'
import Error from 'src/components/blocks/error'

interface Props {
  leaderboardId: string
}

const LeaderBoardsBanner = ({ leaderboardId }: Props) => {
  const { data, loading, error } = useQuery(LEADERBOARD_OFFSET, {
    variables: {
      leaderboardId,
      offset: 0,
    },
  })

  if (loading) return <Loader />

  if (error)
    return <Error>{error?.message ?? 'Error fetching leaderboards'}</Error>

  const filteredData = data.getLeaderboardForOffset.slice(0, 3)

  const iconNamesRank: { [key: number]: IconNames } = {
    1: IconNames.FIRST_PLACE,
    2: IconNames.SECOND_PLACE,
    3: IconNames.THIRD_PLACE,
  }

  return (
    <div className="leaderBoardsBanner">
      {filteredData.map(
        (
          { leaderboardRecord }: { leaderboardRecord: INRLeaderboardRecord },
          index: number
        ) => (
          <TopThreeBadge
            key={index}
            iconName={iconNamesRank[index + 1]}
            rank={index + 1}
            userName={leaderboardRecord.user.userName}
            score={leaderboardRecord.score}
          />
        )
      )}
    </div>
  )
}

export default LeaderBoardsBanner
