import './style.scss'

interface Props {
  hue: string
}

const Line = ({ hue }: Props) => {
  return <hr className={`line--${hue}`} />
}

export default Line
