import { Link } from 'react-router-dom'

import { Gender, PrivacyLevel } from 'src/types/user'
import { useSetupProfile } from 'src/lib/auth/useSetupProfile'
import Icon, { IconNames } from 'src/components/blocks/icons'
import ButtonLarge from 'src/components/blocks/button-large'
import Input from 'src/components/blocks/input'
import Select from 'src/components/blocks/select'
import './style.scss'

const SetupProfile = () => {
  const { handleSetupInput, submitProfile, handleConfirmPw, error } =
    useSetupProfile()

  const genderArray = (Object.keys(Gender) as Array<keyof typeof Gender>).map(
    (key) => ({ value: key, text: key })
  )

  const privacyLevelArray = (
    Object.keys(PrivacyLevel) as Array<keyof typeof PrivacyLevel>
  ).map((key) => ({ value: key, text: key }))

  return (
    <div className="setupProfile">
      <Link to="/">
        <div className="setupProfile__header">
          <Icon name={IconNames.LOGO_SMALL} size={25} />
        </div>
      </Link>
      <div className="setupProfile__content">
        <h2>Set up your profile</h2>
        <p className="bold">
          Tell us something about you so we can personalise workouts in the
          future!
        </p>
        <Icon name={IconNames.PROFILE_PIC} size={50} />
        <Input
          name="userName"
          inputType="text"
          spacingTop
          placeholder="Username*"
          onChange={handleSetupInput}
        />
        <Input
          name="weightKg"
          inputType="number"
          spacingTop
          placeholder="Weight (kg)"
          onChange={handleSetupInput}
        />
        <Select
          name="gender"
          options={genderArray}
          handleInput={handleSetupInput}
        />
        <Select
          name="privacyLevel"
          options={privacyLevelArray}
          handleInput={handleSetupInput}
        />
        <p className="bold setupProfile__pw">
          Create password (min 1 capital letter and 1 number)
        </p>
        <Input
          name="password"
          inputType="password"
          spacingTop
          placeholder="Password*"
          onChange={handleSetupInput}
        />
        <Input
          inputType="password"
          spacingTop
          placeholder="Password verification*"
          onChange={handleConfirmPw}
        />
        <div style={{ pointerEvents: error ? 'none' : 'auto' }}>
          <ButtonLarge children={<h2>Save</h2>} clickHandler={submitProfile} />
        </div>
        <div className="setupProfile__error">{error && error}</div>
      </div>
    </div>
  )
}

export default SetupProfile
