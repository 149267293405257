import { useHistory, Link, useLocation } from 'react-router-dom'

import Icon, { IconNames } from 'src/components/blocks/icons'
import { headerString } from './helpers'
import './style.scss'

const Header = () => {
  const history = useHistory()
  const { pathname } = useLocation<string>()
  const goToPreviousPath = () => {
    history.goBack()
  }

  const renderPreviousArrow = (pathName: string) => {
    return pathName === '/me' ||
      pathName === '/activity-feed' ||
      pathName === '/more'
      ? false
      : true
  }

  return (
    <div className="header">
      <div className="header__logo">
        <Link to="/">
          <Icon name={IconNames.LOGO_SMALL} size={25} />
        </Link>
      </div>
      <div className="header__route">
        {renderPreviousArrow(pathname) && (
          <div
            onClick={() => {
              goToPreviousPath()
            }}
          >
            <Icon name={IconNames.ARROW_LEFT} size={15} />
          </div>
        )}
        <div className="header__text">
          <p>{headerString(pathname)}</p>
        </div>
      </div>
      <div className="header__profile">
        {/* <Link to='/buddies'>
          <Icon name={IconNames.PROFILE_ICON} size={20} />
        </Link> */}
      </div>
      {/* <div className='header__notification'>
        <Icon name={IconNames.NOTIFICATION} size={20} />
      </div> */}
    </div>
  )
}

export default Header
