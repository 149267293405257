import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { IdAndUserName } from 'src/types/queries'
import { MY_ID_AND_USERNAME } from 'src/graphql/queries'
import appUrlByEnvironment from 'src/lib/environment'
import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

// This page/feature is currently taken out
// The buddies feature is not implemented yet
const Invite = () => {
  const QRCode = require('qrcode.react')
  const [displayCopied, setDisplayCopied] = useState<boolean>(false)
  const { data } = useQuery<IdAndUserName>(MY_ID_AND_USERNAME)

  const appLink = appUrlByEnvironment()
  const inviteLink = `${appLink}/profile/${data?.me.id}`

  const linkToClipboard = () => {
    navigator.clipboard.writeText(inviteLink)
    setDisplayCopied(true)
    setTimeout(() => {
      setDisplayCopied(false)
    }, 2000)
  }

  return (
    <div className="invite">
      <div className="invite__user-wrapper">
        <div className="invite__user-wrapper__icon">
          <Icon name={IconNames.PROFILE_PIC} size={20} />
        </div>
        <h4>{data?.me?.userName}</h4>
      </div>
      <div className="invite__link__wrapper" onClick={linkToClipboard}>
        <div className="invite__link__text">
          Share this link with your friends
        </div>
        <div>
          <Icon name={IconNames.SHARE_LINK} size={15} />
        </div>
      </div>
      <div className="invite__copied">
        {displayCopied && <p>Link copied to clipboard!</p>}
      </div>
      <p>or let your friend scan this code: </p>
      <div className="invite__QRcode-wrapper">
        <QRCode size={150} value={inviteLink} />
      </div>
    </div>
  )
}

export default Invite
