import './style.scss'

interface Props {
  name?: string
  autoComplete?: string
  inputType: string
  spacingTop?: boolean
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  cn?: string
}

const Input = ({
  name,
  autoComplete = 'on',
  inputType,
  spacingTop,
  placeholder = '',
  onChange,
  cn = '',
}: Props) => {
  return (
    <div
      className={`${
        spacingTop ? 'inputContainer' : 'inputContainer inputContainer--noSpace'
      } ${cn}`}
    >
      <input
        id={name}
        autoComplete={autoComplete}
        name={name}
        type={inputType}
        placeholder={placeholder}
        onChange={onChange}
        autoCapitalize="off"
        autoCorrect="off"
        autoFocus={false}
      />
    </div>
  )
}

export default Input
