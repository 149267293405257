import { useQuery } from '@apollo/client'

import { Gender, INRMe, PrivacyLevel } from 'src/types/user'
import { ME } from 'src/graphql/queries'
import useEditProfile from 'src/lib/auth/useEditProfile'
import Icon, { IconNames } from 'src/components/blocks/icons'
import ButtonLarge from 'src/components/blocks/button-large'
import Input from 'src/components/blocks/input'
import Select from 'src/components/blocks/select'
import Loader from 'src/components/blocks/loader'
import Error from 'src/components/blocks/error'
import './style.scss'

const EditProfile = () => {
  const { data, loading, error } = useQuery<INRMe>(ME)
  const {
    handleEditInput,
    submitEditProfile,
    error: editProfileError,
    success,
  } = useEditProfile()

  if (loading) return <Loader />

  if (error)
    return <Error>{error?.message ?? 'Error fetching profile data'}</Error>

  const genderArray = (Object.keys(Gender) as Array<keyof typeof Gender>).map(
    (key) => ({
      value: key,
      text: key,
    })
  )
  const privacyArray = [
    { value: PrivacyLevel.Myself, text: 'Me' },
    { value: PrivacyLevel.Public, text: 'Everyone' },
  ]

  return (
    <div className="editProfile">
      <div className="editProfile__info">
        <p>Personal information</p>
        <Icon name={IconNames.PROFILE_PIC} size={50} />
      </div>
      <div className="input-param">Username</div>
      <Input
        name="userName"
        inputType="text"
        spacingTop={false}
        placeholder={data?.me?.userName ?? ''}
        onChange={handleEditInput}
      />
      <div className="input-param">Email</div>
      <Input
        name="email"
        inputType="text"
        spacingTop={false}
        placeholder={data?.me?.email}
        onChange={handleEditInput}
      />
      <div className="input-param">Gender</div>
      <Select
        name="gender"
        options={genderArray}
        handleInput={handleEditInput}
      />
      <div className="input-param">Weight</div>
      <Input
        name="weightKg"
        inputType="number"
        spacingTop={false}
        placeholder="Weight (kg)"
        onChange={handleEditInput}
      />
      <p className="editProfile__fieldDescription">
        Who can see your profile and results?
      </p>
      <Select
        name="privacyLevel"
        options={privacyArray}
        handleInput={handleEditInput}
      />
      <div style={{ pointerEvents: editProfileError ? 'none' : 'auto' }}>
        <ButtonLarge
          children={<h2>Save</h2>}
          clickHandler={submitEditProfile}
          cn="editProfile__btn"
        />
      </div>
      <div className="editProfile__error">
        {editProfileError && editProfileError}
      </div>
      <div className="editProfile__success">{success && 'Profile updated'}</div>
    </div>
  )
}

export default EditProfile
