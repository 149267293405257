import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { INRWorkout } from 'src/types/workouts'
import SingleRound from './single-round'
import { WeightQuery } from 'src/types/queries'
import { MY_WEIGHT } from 'src/graphql/queries'

interface Props {
  workoutData?: INRWorkout
}

const PerRound = ({ workoutData }: Props) => {
  const { data: weightData } = useQuery<WeightQuery>(MY_WEIGHT)
  const weight = weightData?.me.weightKg ?? null
  const [activeIndex, setActiveIndex] = useState(0)

  const handleChangeRound = (index: number) => {
    setActiveIndex(index)
  }

  return (
    <div className="perRoundContainer">
      {workoutData?.rounds.map((__, i) => {
        const text = `Round ${i + 1}`
        const active = i === activeIndex

        return (
          <SingleRound
            key={i}
            index={i}
            text={text}
            active={active}
            workoutData={workoutData}
            clickHandler={handleChangeRound}
            weight={weight}
          />
        )
      })}
    </div>
  )
}

export default PerRound
