import { useHistory } from 'react-router-dom'

import useLogin from 'src/lib/auth/useLogin'
import ButtonLarge from 'src/components/blocks/button-large'
import Input from 'src/components/blocks/input'
import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

const Login = () => {
  const { handleLoginInput, handleLogin, error } = useLogin()
  const history = useHistory()

  const redirectSignup = () => {
    history.push('/signup')
  }

  const redirectForgotPw = () => {
    history.push('/forgot-password')
  }

  return (
    <div className="login">
      <Icon name={IconNames.LOGO_SMALL} size={30} />
      <div className="login__body">
        <h2>Login</h2>
        <div className="login__sub">
          No account yet? <span onClick={redirectSignup}>Register here!</span>
        </div>
        <div className="login__form">
          <Input
            name="email"
            inputType="text"
            spacingTop={true}
            placeholder="Email address"
            onChange={handleLoginInput}
            cn="login__input"
          />
          <Input
            name="password"
            inputType="password"
            spacingTop={true}
            placeholder="Password"
            onChange={handleLoginInput}
            cn="login__input"
          />
          <div className="login__error">{error && error}</div>
        </div>
        <div style={{ pointerEvents: error ? 'none' : 'auto' }}>
          <ButtonLarge
            children={<h2 className="login__button__login">Sign in</h2>}
            clickHandler={handleLogin}
            cn="login__button"
            buttonType="tertiary"
          />
        </div>
        <div className="login__forgotPw" onClick={redirectForgotPw}>
          Forgot your password?
        </div>
      </div>
    </div>
  )
}

export default Login
