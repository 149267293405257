import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

interface Props {
  filled: boolean
  ratingValue: number
  mouseEnter: any
  mouseLeave: () => void
}

const Star = ({ filled, ratingValue, mouseEnter, mouseLeave }: Props) => {
  return (
    <>
      {filled ? (
        <AiFillStar
          size="1.5rem"
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        />
      ) : (
        <AiOutlineStar
          size="1.5rem"
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        />
      )}
    </>
  )
}

export default Star
