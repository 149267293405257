import { ResponsiveContainer, XAxis, YAxis, Area, AreaChart } from 'recharts'

import { INRRound } from 'src/types/workouts'
import { getRoundDuration } from 'src/lib/stats'
import {
  getEvaluatedRoundIntensity,
  getOldIntensityPerRound,
} from 'src/lib/intensity'
import { workoutIsBeforeJune2024 } from 'src/lib/utilities'
import './style.scss'

interface IntensityGraphRoundProps {
  roundData: INRRound
  workoutCreatedAt: string
  weight: number | null
}

const IntensityGraphRound = ({
  roundData,
  workoutCreatedAt,
  weight,
}: IntensityGraphRoundProps) => {
  const isBefore = workoutIsBeforeJune2024(workoutCreatedAt)
  const roundTime = getRoundDuration(roundData)
  const intensityRoundPerSecond = isBefore
    ? getOldIntensityPerRound(roundData)
    : getEvaluatedRoundIntensity(roundData, weight)

  const intensityGraphData = intensityRoundPerSecond?.map(
    (value: number, index: number) => ({
      intensity: value,
      index,
    })
  )

  return (
    <ResponsiveContainer width={300} height="100%">
      <AreaChart data={intensityGraphData}>
        <XAxis
          tick={false}
          dataKey="index"
          type="number"
          domain={[0, roundTime]}
        />
        <YAxis tick={false} hide={true} domain={[0, 5]} />
        <Area
          dataKey="intensity"
          type="basis"
          fill="#FB7558"
          stroke="#FB7558"
          fillOpacity={0.5}
          strokeWidth={4}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default IntensityGraphRound
