import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { LOGIN } from 'src/graphql/mutations'
import { checkEmptyField, handleAuthError, handleInput } from './helpers'

interface LoginState {
  email: string
  password: string
}

const useLogin = () => {
  const history = useHistory()
  const [error, setError] = useState<string>('')
  const [inputValues, setInputValues] = useState<LoginState>({
    email: '',
    password: '',
  })
  const handleLoginInput = handleInput(setInputValues)
  const handleLoginError = handleAuthError(setError)

  const [login] = useMutation(LOGIN, {
    onError: (err) => {
      handleLoginError(err.message)
    },
    onCompleted({ login }) {
      localStorage.setItem('NEXTROUND_APP_TOKEN', login)
      history.push('/activity-feed')
    },
  })

  const handleLogin = () => {
    const { email, password } = inputValues
    const inputErr = checkEmptyField(email, password)
    if (!!inputErr) {
      handleLoginError(inputErr)
    } else {
      login({ variables: { email, password } })
    }
  }

  return { handleLoginInput, handleLogin, error }
}

export default useLogin
