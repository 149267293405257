import { INRRound } from 'src/types/workouts'
import { getXaxisTicks } from 'src/lib/graph'
import { sumUp } from 'src/lib/utilities'
import ForceGraphRound from './force-graph-singleround'
import IntensityGraphRound from './intensity-graph-singleround'
import CustomXAxis from './custom-x-axis'
import './style.scss'

interface Props {
  roundData: INRRound
  isForceGraph: boolean
  index: number
  allRoundTimes: number[]
  maxForce: number
  workoutCreatedAt: string
  weight: number | null
}

const SingleRoundGraph = ({
  roundData,
  isForceGraph,
  index,
  allRoundTimes,
  maxForce,
  workoutCreatedAt,
  weight,
}: Props) => {
  const prevRoundTimes = allRoundTimes?.filter(
    (_, roundIndex) => roundIndex < index
  )
  const firstValue = sumUp(prevRoundTimes!)
  const lastValue = firstValue + allRoundTimes![index]
  const { secondValue, thirdValue, fourthValue } = getXaxisTicks(
    firstValue,
    lastValue
  )

  const xAxisTicks = [
    firstValue,
    secondValue,
    thirdValue,
    fourthValue,
    lastValue,
  ]

  const renderGraph = isForceGraph ? (
    <ForceGraphRound
      workoutCreatedAt={workoutCreatedAt}
      roundData={roundData}
      maxForce={maxForce}
    />
  ) : (
    <IntensityGraphRound
      workoutCreatedAt={workoutCreatedAt}
      roundData={roundData}
      weight={weight}
    />
  )

  return (
    <div className="roundsGraph__singleRoundWrapper">
      {renderGraph}
      <CustomXAxis displayFirstTick={index === 0} xAxisTicks={xAxisTicks} />
    </div>
  )
}

export default SingleRoundGraph
