import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { INRWorkout } from 'src/types/workouts'
import { FEED, MY_ID } from 'src/graphql/queries'
import { IdQuery } from 'src/types/queries'
import useTabsPanel from 'src/hooks/useTabsPanel'
import TabsPanel from 'src/components/blocks/tabs-panel'
import WorkoutCard from 'src/components/blocks/workout-card'
import NewWorkout from 'src/components/blocks/new-workout'
import Loader from 'src/components/blocks/loader'
import Error from 'src/components/blocks/error'
import NoWorkouts from './no-workouts'
import './style.scss'

interface FeedQuery {
  feed: INRWorkout[]
}

// Users can see all their workouts on this page
// The tab selector allows users to filter between all workouts(themselves and their friends) and their own
// The friends/buddies feature is not implemented yet, so both tabs should show the same workouts
const ActivityFeed = () => {
  const { data, loading, error } = useQuery<FeedQuery>(FEED)
  const { data: meData } = useQuery<IdQuery>(MY_ID)
  const myId = meData?.me.id
  const [displayNewWorkout, setDisplayNewWorkout] = useState<boolean>(false)

  const { activeTab, selectTab } = useTabsPanel()
  const tabs = [{ text: 'all' }, { text: 'mine' }]

  if (loading) return <Loader />

  if (error) return <Error>{error?.message ?? 'Error fetching feed'}</Error>

  const reverseFeed = data?.feed ? [...data.feed].reverse() : []

  const filteredFeed =
    activeTab === 0
      ? reverseFeed
      : reverseFeed.filter((workout) => workout?.user?.id === myId)

  const renderFeed = loading ? (
    <Loader />
  ) : !data?.feed.length ? (
    <NoWorkouts />
  ) : (
    data &&
    filteredFeed.map((workout, i) => {
      if (workout.user) return <WorkoutCard key={i} {...workout} />
    })
  )

  return (
    <div className="activity-feed">
      <div className="activity-feed__selector">
        <TabsPanel
          tabs={tabs}
          activeIndex={activeTab}
          handleClick={selectTab}
        />
      </div>
      {displayNewWorkout && (
        <NewWorkout
          displayPopup={() => {
            setDisplayNewWorkout(!displayNewWorkout)
          }}
        />
      )}
      <div className="activity-feed__workouts">{renderFeed}</div>
    </div>
  )
}

export default ActivityFeed
