import gql from 'graphql-tag'

//TODO these could all go into custom hooks for their specific queries
export const ME = gql`
  {
    me {
      userName
      id
      email
      name
      gender
      weightKg
      privacyLevel
      pin
    }
  }
`

export const MY_ID = gql`
  {
    me {
      id
    }
  }
`

export const MY_WEIGHT = gql`
  {
    me {
      weightKg
    }
  }
`

export const MY_ID_AND_FOLLOWS = gql`
  {
    me {
      id
      followers {
        id
        state
      }
    }
  }
`

export const MY_ID_AND_USERNAME = gql`
  {
    me {
      id
      userName
    }
  }
`

export const MY_USERNAME_AND_WORKOUTS = gql`
  {
    me {
      userName
      workouts {
        id
        name
        duration
        numOfHits
        totalForce
        maxSpeed
        maxForce
        createdAt
        user {
          id
          name
          userName
        }
        claps {
          id
          user {
            id
          }
        }
        highlights {
          text
        }
      }
    }
  }
`

export const FEED = gql`
  query feed {
    feed(queryArgs: { size: 50 }) {
      id
      name
      createdAt
      duration
      numOfHits
      totalForce
      maxSpeed
      user {
        id
        name
        userName
      }
      claps {
        id
        user {
          id
        }
      }
      highlights {
        text
      }
    }
  }
`

export const GET_USER = gql`
  {
    user {
      id
      email
      userName
      name
    }
  }
`

export const GET_WORKOUTS = gql`
  {
    workoutTemplate {
      id
      name
      description
      workoutType
      variants {
        id
        difficultyLevel
        rounds {
          id
          breakSeconds
          exercises {
            id
            breakSeconds
            seconds
            calories
            moves {
              id
              name
              tip
            }
          }
        }
      }
      categories {
        id
        name
      }
    }
  }
`

export const GET_FOLLOW = gql`
  query follow($id: String!) {
    follow(input: { id: $id }) {
      id
      state
    }
  }
`

export const FOLLOW_REQUESTS = gql`
  {
    me {
      followers {
        id
        state
      }
    }
  }
`

export const USER = gql`
  query user($id: String) {
    user(input: { id: $id }) {
      userName
      followers {
        id
      }
      following {
        id
      }
      workouts {
        id
        name
        duration
        numOfHits
        totalForce
        maxSpeed
        maxForce
        createdAt
        user {
          id
          name
          userName
        }
        claps {
          id
          user {
            id
          }
        }
        highlights {
          text
        }
      }
    }
  }
`

export const WORKOUT = gql`
  query workout($id: String) {
    workout(input: { id: $id }) {
      name
      createdAt
      workoutType
      rating
      notes
      duration
      numOfHits
      totalForce
      maxForce
      minForce
      avgForce
      avgSpeed
      maxSpeed
      minSpeed
      workoutTemplate {
        variants {
          rounds {
            exercises {
              challenges {
                id
                leaderboard {
                  id
                }
                constraints {
                  min
                  max
                  type
                }
              }
            }
          }
        }
      }
      user {
        userName
        id
      }
      rounds {
        countdownSeconds
        breakSeconds
        createdAt
        duration
        numOfHits
        totalForce
        maxForce
        minForce
        avgForce
        avgSpeed
        maxSpeed
        minSpeed
        exercises {
          breakSeconds
          seconds
          started
          moves {
            name
          }
          hits {
            force
            height
            angle
            punchedAt
          }
        }
      }
      highlights {
        text
      }
    }
  }
`

export const LEADERBOARD_OFFSET = gql`
  query getLeaderboardForOffset($offset: Int!, $leaderboardId: String!) {
    getLeaderboardForOffset(
      input: { offset: $offset, leaderboardId: $leaderboardId }
    ) {
      leaderboardRecord {
        score
        user {
          userName
          id
        }
      }
      offset
    }
  }
`

export const LEADERBOARD_USER = gql`
  query getLeaderboardForUser($email: String!, $leaderboardId: String!) {
    getLeaderboardForUser(
      input: { email: $email, leaderboardId: $leaderboardId }
    ) {
      leaderboardRecord {
        score
        user {
          userName
          id
        }
      }
      offset
    }
  }
`

export const CHALLENGE_TEMPLATE = gql`
  query challengeTemplate($id: String!) {
    challengeTemplate(input: { id: $id }) {
      leaderboard {
        id
        records {
          id
          score
          user {
            id
          }
        }
      }
    }
  }
`
