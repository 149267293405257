import React from 'react'
import { Link } from 'react-router-dom'

import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

interface Props {
  amount: string
  link: string
  text: string
}

//! Re-do component + styles when this feature is re-implemented
const RequestInviteButton = ({ amount, link, text }: Props) => {
  return (
    <div className="req-invite">
      <div className="req-invite__number">
        <p className="large">
          <b>{amount}</b>
        </p>
      </div>
      <Link className="req-invite__text" to={link}>
        <p>
          <b>{text}</b>
        </p>
      </Link>
      <Link className="req-invite__icon" to={link}>
        <Icon name={IconNames.ARROW_DOWN} size={10} />
      </Link>
    </div>
  )
}

export default RequestInviteButton
