import './style.scss'

interface Props {
  tooltip: JSX.Element
}

const Tooltip = ({ tooltip }: Props) => {
  return (
    <div className="tooltip">
      <div className="tooltip__content">{tooltip}</div>
    </div>
  )
}

export default Tooltip
