import { useHistory } from 'react-router-dom'
import noPermissionImg from 'src/assets/images/Union.png'
import Button from 'src/components/blocks/button'

const NoPermission = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/edit-profile')
  }

  return (
    <div className="leaderBoardsNoPermission">
      <img src={noPermissionImg} />
      <div className="leaderBoardsNoPermission__text">
        <h2>You didn’t give permission</h2>
        <h4 className="light">
          Let’s change that to see your position on the leaderboard!
        </h4>
      </div>
      <Button buttonType="tertiary" clickHandler={handleClick}>
        <h4>Go to settings</h4>
      </Button>
    </div>
  )
}

export default NoPermission
