import './style.scss'

interface Props {
  children: string
}

const Error = ({ children }: Props) => {
  return <div className="error">{children}</div>
}

export default Error
