import { INRWorkout } from 'src/types/workouts'
import { getAverage, toFullTime } from 'src/lib/utilities'
import Line from 'src/components/blocks/line'
import Icon, { IconNames } from 'src/components/blocks/icons'
import DetailCard from '../detail-card'
import Insights from '../insights'
import { getEvaluatedRoundIntensity } from 'src/lib/intensity'
import './style.scss'

interface Props {
  index: number
  text: string
  active: boolean
  workoutData: INRWorkout
  clickHandler: (index: number, active: boolean) => any
  weight: number | null
}

const SingleRound = ({
  index,
  text,
  active,
  workoutData,
  clickHandler,
  weight,
}: Props) => {
  const selectedRound = workoutData.rounds[index]
  const avgIntensity = getAverage(
    getEvaluatedRoundIntensity(selectedRound, weight)
  )

  return (
    <>
      <div
        className="display-round"
        onClick={() => clickHandler(index, active)}
      >
        <b>{text}</b>
        <div>
          <Icon name={IconNames.ARROW_UP} size={10} />
        </div>
      </div>
      {active && (
        <>
          <h5>Exercises</h5>
          {selectedRound.exercises.map(({ moves, seconds }, i) => {
            const fullTime = toFullTime(seconds)

            return (
              <div key={i} className="exercise-card">
                <div className="exercise-card__moves">
                  {moves?.map(({ name }: any, i: any) => {
                    const singleMove =
                      index === moves.length - 1 ? name : `${name}, `
                    return (
                      <div key={i} className="exercise-card__move">
                        {singleMove}
                      </div>
                    )
                  })}
                </div>
                <div className="exercise-card__time">
                  <Icon name={IconNames.CLOCK} size={10} />
                  {fullTime}
                </div>
              </div>
            )
          })}
          <Line hue="neutral" />
          <Insights
            workoutData={workoutData}
            index={index}
            weight={weight}
            singleRound={true}
          />
          <Line hue="neutral" />
          <DetailCard
            avgForce={selectedRound.avgForce}
            avgSpeed={selectedRound.avgSpeed}
            duration={selectedRound.duration}
            maxForce={selectedRound.maxForce}
            maxSpeed={selectedRound.maxSpeed}
            minForce={selectedRound.minForce}
            numOfHits={selectedRound.numOfHits}
            totalForce={selectedRound.totalForce}
            minSpeed={selectedRound.minSpeed}
            avgIntensity={avgIntensity}
          />
        </>
      )}
    </>
  )
}

export default SingleRound
