import React from 'react'

import Popup from 'src/components/blocks/popup'
import { newWorkoutContent } from './constants'
import './style.scss'

interface NewWorkoutProps {
  displayPopup: () => void
}

const NewWorkout: React.FC<NewWorkoutProps> = ({ displayPopup }) => {
  return (
    <div className="new-workout">
      <Popup
        content={newWorkoutContent}
        displayPopup={displayPopup}
        showLogo={false}
      />
    </div>
  )
}

export default NewWorkout
