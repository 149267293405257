import SingleQuestion from 'src/components/blocks/single-question'
import { QuestionType } from 'src/types/blocks'
import './style.scss'

interface Props {
  title: string
  questions: QuestionType[]
}

const FaqCategory = ({ title, questions }: Props) => {
  return (
    <>
      <div className="faq-category-title">{title}</div>
      {questions.map(({ text, tooltip }, i) => {
        return <SingleQuestion key={i} text={text} tooltip={tooltip} />
      })}
    </>
  )
}

export default FaqCategory
