import { useState } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { INRWorkout } from 'src/types/workouts'
import { IdQuery, WeightQuery } from 'src/types/queries'
import { MY_ID, MY_WEIGHT } from 'src/graphql/queries'
import NotePopup from './note-modal'
import Insights from '../insights'
import DetailCard from '../detail-card'
import Note from '../note'
import Rating from '../rating'
import { getAverage } from 'src/lib/utilities'
import { getEvaluatedWorkoutIntensity } from 'src/lib/intensity'
import './style.scss'

interface Props {
  workoutData: INRWorkout
}

const Overall = ({ workoutData }: Props) => {
  const [displayNote, setDisplayNote] = useState<boolean>(false)

  const { data: weightData } = useQuery<WeightQuery>(MY_WEIGHT)
  const weight = weightData?.me.weightKg ?? null

  const { data: meData } = useQuery<IdQuery>(MY_ID)
  const myId = meData?.me.id ?? ''
  const userId = workoutData?.user?.id

  const workout: any = useParams()
  const avgIntensity = getAverage(
    getEvaluatedWorkoutIntensity(workoutData, weight ?? null)
  )

  return (
    <div className="overallContainer">
      <Insights workoutData={workoutData} singleRound={false} weight={weight} />
      <DetailCard
        avgForce={workoutData.avgForce}
        avgSpeed={workoutData.avgSpeed}
        duration={workoutData.duration}
        maxForce={workoutData.maxForce}
        maxSpeed={workoutData.maxSpeed}
        minForce={workoutData.minForce}
        numOfHits={workoutData.numOfHits}
        totalForce={workoutData.totalForce}
        minSpeed={workoutData.minSpeed}
        avgIntensity={avgIntensity}
      />
      {myId === userId && (
        <Note setDisplayNote={setDisplayNote} notes={workoutData.notes} />
      )}
      {displayNote && myId === userId && (
        <NotePopup
          id={workout.id}
          notes={workoutData.notes}
          setDisplayNote={setDisplayNote}
          displayNote={displayNote}
        />
      )}
      {
        <Rating
          rating={workoutData.rating}
          user={workoutData?.user}
          myId={myId}
        />
      }
    </div>
  )
}

export default Overall
