import { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { RESET_PASSWORD } from 'src/graphql/mutations'
import Input from 'src/components/blocks/input'
import Icon, { IconNames } from 'src/components/blocks/icons'
import ButtonLarge from 'src/components/blocks/button-large'
import ResetPasswordPopup from 'src/components/auth/reset-password/reset-pw-popup'
import './style.scss'

interface ResetPasswordState {
  password: string
  repeatPassword: string
  verifyToken: string
}

const ResetPassword = () => {
  const location = useLocation()
  const history = useHistory()
  const [error, setError] = useState<string>('')
  const [displayPopup, setDisplayPopup] = useState<boolean>(false)
  const [inputValues, setInputValues] = useState<ResetPasswordState>({
    password: '',
    repeatPassword: '',
    verifyToken: '',
  })

  const navigateToHome = () => {
    history.push('/')
  }

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onError: (err) => {
      setError(err.message)
    },
    onCompleted: () => {
      setDisplayPopup(true)
    },
  })

  const handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [evt.target.name]: evt.target.value,
    })
  }

  const handleSubmit = () => {
    const passwordFormat = /^(?=.*[A-Z])(?=.*\d).*$/

    if (
      !inputValues.password.match(passwordFormat) ||
      !inputValues.repeatPassword.match(passwordFormat)
    ) {
      return setError(
        'Password must contain at least one capital letter and one number'
      )
    }

    if (inputValues.password !== inputValues.repeatPassword) {
      return setError('Passwords do not match.')
    }

    const variables = {
      newPassword: inputValues.password,
      token: inputValues.verifyToken,
    }

    return resetPassword({
      variables,
    })
  }

  useEffect(() => {
    const verifyToken = new URLSearchParams(location.search).get('token')
    if (!verifyToken) return history.push('/')
    setInputValues({
      ...inputValues,
      verifyToken,
    })
  }, [])

  return (
    <>
      {displayPopup && (
        <ResetPasswordPopup
          display={displayPopup}
          setDisplay={setDisplayPopup}
        />
      )}
      <div className="resetPassword">
        <div className="resetPassword__header">
          <Icon name={IconNames.LOGO_SMALL} size={30} />
          <Icon
            name={IconNames.CROSS}
            size={20}
            customClickEvent={navigateToHome}
          />
        </div>
        <div className="resetPassword__body">
          <h2>Create your password</h2>
          <Input
            name="password"
            inputType="password"
            spacingTop
            placeholder="Password"
            onChange={handleInput}
          />
          <Input
            name="repeatPassword"
            inputType="password"
            spacingTop
            placeholder="Repeat password"
            onChange={handleInput}
          />
          <div className="resetPassword__card">
            <p>Password must contain at least 6 letters and 2 digits.</p>
          </div>
          <div style={{ pointerEvents: error ? 'none' : 'auto' }}>
            <ButtonLarge
              buttonType="tertiary"
              children={<h2>Confirm</h2>}
              clickHandler={handleSubmit}
            />
          </div>
          <div className="resetPassword__error">{error && error}</div>
          <div className="resetPassword__help">
            Doesn't work?{' '}
            <Link
              target="_blank"
              to={{ pathname: 'https://nextroundboxing.com/contact/' }}
            >
              Click here for help
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
