import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { IdQuery } from 'src/types/queries'
import { INRClap, INRWorkout } from 'src/types/workouts'
import { CLAP } from 'src/graphql/mutations'
import { MY_ID } from 'src/graphql/queries'
import { toFullTime } from 'src/lib/utilities'
import PunchData from 'src/components/blocks/punch-data'
import Icon, { IconNames } from 'src/components/blocks/icons'
import Time from 'src/components/blocks/time'
import User from 'src/components/blocks/user'
import Error from 'src/components/blocks/error'
import './style.scss'

const WorkoutCard = (props: INRWorkout) => {
  const { data: meData } = useQuery<IdQuery>(MY_ID)
  const myId = meData?.me.id
  const {
    claps,
    user,
    createdAt,
    highlights,
    id,
    name: workoutName,
    duration,
    maxSpeed,
    totalForce,
    numOfHits,
  } = props

  const [error, setError] = useState<string>('')
  const [clap] = useMutation(CLAP, {
    onError: (err) => {
      setError(err.message)
      setTimeout(() => {
        setError('')
      }, 5000)
    },
  })

  const findMyClap = (myId: string = '', claps: INRClap[] = []) =>
    claps.some((clap) => clap.user.id === myId)

  const handleClick = (id: string) => {
    const enableClick =
      findMyClap(myId, claps) || myId === user?.id ? false : true
    enableClick &&
      clap({ variables: { workoutId: id } }).then(() =>
        window.location.reload()
      )
  }

  const clapIcon =
    myId === user?.id ? (
      <Icon name={IconNames.CLAPS} />
    ) : findMyClap(myId, claps) ? (
      <Icon name={IconNames.CLAPPED} />
    ) : (
      <Icon name={IconNames.CLAP} />
    )

  const fullTime = toFullTime(duration)

  return (
    <div className="workoutCard">
      <User id={user?.id} userName={user?.userName} createdAt={createdAt} />
      <Link to={`/workout/${id}`}>
        <span className="workoutCard__nameTime">
          <p>{workoutName}</p>
          <Time timer={<p>{fullTime}</p>} iconSize={13} />
        </span>
        <PunchData
          numOfHits={numOfHits}
          totalForce={totalForce}
          maxSpeed={maxSpeed}
        />
        <p
          className="workoutCard__highlight"
          dangerouslySetInnerHTML={{
            __html: highlights?.length ? highlights[0].text : '',
          }}
        />
      </Link>
      <div className="workoutCard__claps" onClick={() => handleClick(id)}>
        {clapIcon}
        {myId === user?.id && <p className="large">{claps?.length}</p>}
      </div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            width: '100%',
            textAlign: 'left',
            zIndex: 999,
            position: 'absolute',
            bottom: '.5rem',
          }}
        >
          {error && <Error>{error}</Error>}
        </div>
      </div>
    </div>
  )
}

export default WorkoutCard
