import { ReactElement } from 'react'

import Header from './header'
import Footer from './footer'

interface Props {
  children: ReactElement | ReactElement[]
}

const Main = ({ children }: Props) => {
  return (
    <>
      <Header />
      <div className="component-container">{children}</div>
      <Footer />
    </>
  )
}

export default Main
