const AvgLine = ({ value = 0 }: { value: number }) => {
  return (
    <div
      className="roundsGraph__y-axis__avg"
      style={{ bottom: `calc(${value}%)` }}
    >
      <div>
        <p>Avg</p>
        <hr className="roundsGraph__y-axis__avgLine" />
      </div>
    </div>
  )
}

export default AvgLine
