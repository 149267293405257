import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { Gender, INRUserWithPassword, PrivacyLevel } from 'src/types/user'
import { VERIFY_EMAIL } from 'src/graphql/mutations'
import {
  checkEmptyFieldWithPassword,
  handleAuthError,
  handleInput,
} from './helpers'

interface SetupProfileState extends INRUserWithPassword {
  verifyToken: string
}

export const initProfileState: SetupProfileState = {
  verifyToken: '',
  userName: '',
  gender: Gender.Male,
  weightKg: 0,
  privacyLevel: PrivacyLevel.Public,
  password: '',
}

export const useSetupProfile = () => {
  const location = useLocation()
  const history = useHistory()

  const [inputValues, setInputValues] =
    useState<SetupProfileState>(initProfileState)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleSetupError = handleAuthError(setError)
  const handleSetupInput = handleInput(setInputValues)
  const handleConfirmPw = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(evt.target.value)

  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    onError: (err) => {
      handleSetupError(err.message)
    },
    onCompleted: () => {
      history.push('/')
    },
  })

  useEffect(() => {
    const verifyToken = new URLSearchParams(location.search).get('token') ?? ''
    setInputValues({
      ...inputValues,
      verifyToken,
    })
  }, [])

  const submitProfile = () => {
    const sumbitProfileErr = checkEmptyFieldWithPassword(
      inputValues,
      confirmPassword
    )
    if (!!sumbitProfileErr) {
      handleSetupError(sumbitProfileErr)
    } else {
      verifyEmail({
        variables: { ...inputValues },
      })
    }
  }

  return { handleSetupInput, submitProfile, handleConfirmPw, error }
}
