import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { IdAndFollowsQuery } from 'src/types/queries'
import { FOLLOW, UNFOLLOW } from 'src/graphql/mutations'
import { MY_ID_AND_FOLLOWS, USER } from 'src/graphql/queries'
import Button from 'src/components/blocks/button'
import Icon, { IconNames } from 'src/components/blocks/icons'
import ProfileCard from 'src/components/blocks/profile-card'
import WorkoutCard from 'src/components/blocks/workout-card'
import './style.scss'

// This page/feature is currently taken out
// The buddies feature is not implemented yet
// This page represents the profile page of *another* user
const Profile = () => {
  const params: any = useParams()
  const userId = params.id
  const { data } = useQuery<IdAndFollowsQuery>(MY_ID_AND_FOLLOWS)
  const {
    data: userData,
    loading,
    error,
  } = useQuery(USER, { variables: { id: userId } })
  const user = userData?.user[0]

  const [displayError, setDisplayError] = useState<string>('')
  const myId = data?.me?.id
  const following = !!myId
    ? data?.me?.followers?.some((user: any) => user?.to.id === userId)
    : null
  const findFollow = !!myId
    ? data?.me?.followers?.find((user: any) => user?.to.id === userId)
    : null

  const [follow] = useMutation(FOLLOW, {
    onError: (err) => {
      setDisplayError(err.message)
      setTimeout(() => {
        setDisplayError('')
      }, 5000)
    },
  })

  const [unfollow] = useMutation(UNFOLLOW, {
    onError: (err) => {
      setDisplayError(err.message)
      setTimeout(() => {
        setDisplayError('')
      }, 5000)
    },
  })

  const unFollowUser = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'Stop following' && !!findFollow?.id) {
      const followId = findFollow.id
      unfollow({ variables: { followId } }).then(() => window.location.reload())
    }
  }

  const followUser = () => {
    follow({ variables: { userId: userId } }).then(() =>
      window.location.reload()
    )
  }

  const followButton = following ? (
    <div style={{ width: '25%' }}>
      <select
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
          unFollowUser(e)
        }}
        defaultValue="Following"
      >
        <option value="Following">Following</option>
        <option value="Stop following">Stop following</option>
      </select>
    </div>
  ) : (
    <Button clickHandler={followUser} children="Follow" />
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>{`{Error: ${error}`}</p>

  return (
    <div className="profile">
      <div className="profile__header">
        <div className="profile__user">
          <Icon name={IconNames.PROFILE_PIC} size={35} />
          <p className="large">
            <b>{user?.userName && user?.userName}</b>
          </p>
        </div>
        {/* <div className='profile__follow'>
          <div className='profile__follow__left'>
            <Link to='/buddies'>
              <p className='large'>{user?.followers.length}</p>
              <p>{user?.followers.length === 1 ? 'Follower' : 'Followers'}</p>
            </Link>
          </div>
          <div className='profile__vl' />
          <div className='profile__follow__left'>
            <Link to='/buddies'>
              <p className='large'>{user?.following.length}</p>
              <p>Following</p>
            </Link>
          </div>
        </div> */}
      </div>
      <div className="profile__follow__buttons">{followButton}</div>
      <ProfileCard workouts={user?.workouts} />
      <h5>Workouts</h5>
      <div className="profile__workouts">
        {[...user?.workouts].reverse().map((workout, i) => {
          if (workout.user) return <WorkoutCard key={i} {...workout} />
        })}
      </div>
    </div>
  )
}

export default Profile
