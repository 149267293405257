import { useState } from 'react'

const useTabsPanel = () => {
  const [activeTab, setActiveTab] = useState(0)

  const selectTab = (index: number) => {
    setActiveTab(index)
  }

  return { activeTab, selectTab }
}

export default useTabsPanel
