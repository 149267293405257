import { toFullTime } from 'src/lib/utilities'
import './style.scss'

interface Props {
  displayFirstTick: boolean
  xAxisTicks: number[]
}

const CustomXAxis = ({ displayFirstTick, xAxisTicks }: Props) => {
  return (
    <div className="roundsGraph__x-axis__wrapper">
      <hr className="roundsGraph__x-axis__line" />
      <div style={{ position: 'relative' }}>
        <div className="roundsGraph__x-axis__ticksWrapper">
          {xAxisTicks.map((tick: number, tickIndex) => {
            return !displayFirstTick && tickIndex === 0 ? (
              <p key={tickIndex} />
            ) : (
              <p key={tickIndex}>{toFullTime(Math.round(tick)).slice(3)}</p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CustomXAxis
