import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ActivityFeed from './components/pages/activity-feed'
import Buddies from './components/pages/buddies/'
import Disclaimer from './components/pages/disclaimer/'
import EditProfile from './components/pages/edit-profile'
import Faq from './components/pages/faq/'
import About from './components/pages/about'
import FollowRequests from './components/pages/follow-requests'
import Invite from './components/pages/invite'
import Me from './components/pages/me'
import More from './components/pages/more/'
import Notifications from './components/pages/notifications'
import Profile from './components/pages/profile/'
import WorkoutDetails from './components/pages/workout-details'
import SaveWorkout from './components/pages/save-workout'
import Welcome from './components/auth/welcome/'
import Signup from './components/auth/signup/'
import SetupProfile from './components/auth/setup-profile'
import ProtectedRoute from './components/auth/protected-route'
import ResetPassword from './components/auth/reset-password'
import Login from './components/auth/login'
import './app.scss'
import ForgotPassword from './components/auth/forgot-password'

const App: React.FC = () => {
  return (
    <div className="nextround">
      <Switch>
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/verify-email/" component={SetupProfile} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/">
          <Redirect to="/activity-feed" />
        </Route>
        <ProtectedRoute exact path="/activity-feed" component={ActivityFeed} />
        <ProtectedRoute exact path="/workout/:id" component={WorkoutDetails} />
        <ProtectedRoute exact path="/save/:id" component={SaveWorkout} />
        <ProtectedRoute exact path="/buddies" component={Buddies} />
        <ProtectedRoute exact path="/disclaimer" component={Disclaimer} />
        <ProtectedRoute exact path="/edit-profile" component={EditProfile} />
        <ProtectedRoute exact path="/faq" component={Faq} />
        <ProtectedRoute exact path="/about" component={About} />
        {/* <ProtectedRoute exact path='/feedback' component={Feedback} /> */}
        <ProtectedRoute
          exact
          path="/follow-requests"
          component={FollowRequests}
        />
        <ProtectedRoute exact path="/invite" component={Invite} />
        <ProtectedRoute exact path="/me" component={Me} />
        <ProtectedRoute exact path="/more" component={More} />
        <ProtectedRoute exact path="/notifications" component={Notifications} />
        <ProtectedRoute exact path="/profile/:id" component={Profile} />
      </Switch>
    </div>
  )
}

export default App
