import './style.scss'

// This page/feature is currently taken out
// The buddies feature is not implemented yet
const FollowRequests = () => {
  return (
    <div className="follow-requests">
      <p className="follow-requests__inDevelopment">
        Follow functionality currently in development
      </p>
      {/* {tempUsers.map(({ image, name }, i) => {
        return <SingleRequest key={i} image={image} name={name} />
      })} */}
    </div>
  )
}

export default FollowRequests
