import { toFullTime } from 'src/lib/utilities'
import { INRStats } from 'src/types/stats'
import Icon, { IconNames } from 'src/components/blocks/icons'
import DetailCardRow from './detail-card-row'
import './style.scss'

const DetailCard = (props: INRStats & { avgIntensity: number }) => {
  const {
    avgForce,
    avgSpeed,
    maxForce,
    maxSpeed,
    minForce,
    minSpeed,
    numOfHits,
    totalForce,
    duration,
    avgIntensity,
  } = props

  const fullTime = toFullTime(duration)

  return (
    <div className="detailCard">
      <DetailCardRow
        icon={<Icon name={IconNames.DURATION} size={12} />}
        property="Duration"
        value={fullTime}
      />
      <DetailCardRow
        icon={<Icon name={IconNames.PUNCHES} size={12} />}
        property="Hits"
        value={String(numOfHits)}
      />
      <DetailCardRow
        icon={<Icon name={IconNames.FORCE} size={12} />}
        property="Total Force"
        value={`${Math.round(totalForce)} kg`}
      />
      <DetailCardRow
        property="Avg. Force"
        value={`${Math.round(avgForce)} kg`}
      />
      <DetailCardRow property="Lowest" value={`${Math.round(minForce)} kg`} />
      <DetailCardRow property="Highest" value={`${Math.round(maxForce)} kg`} />
      <DetailCardRow
        icon={<Icon name={IconNames.FREQUENCY} size={12} />}
        property="Avg Speed"
        value={`${Math.round(avgSpeed)} hits/m`}
      />
      <DetailCardRow
        property="Lowest"
        value={`${Math.round(minSpeed)} hits/m`}
      />
      <DetailCardRow
        property="Highest"
        value={`${Math.round(maxSpeed)} hits/m`}
      />
      <DetailCardRow
        property="Avg Intensity"
        value={`${Math.round(avgIntensity * 10) / 10}`}
      />
    </div>
  )
}

export default DetailCard
