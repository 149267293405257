import { UserWeight } from 'src/types/user'
import {
  INRExercise,
  INRRound,
  INRWorkout,
  INRWorkoutHit,
  INRWorkoutSessionTime,
} from '../types/workouts'
import {
  convertHitsPerSec,
  convertHitsToSec,
  getMax2Decimal,
} from './utilities'

// These functions should be aligned with the training app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

// intensity is calculated over the last 10 seconds
// during the first 10 seconds of the exercise, the intensity is calculated based on the time passed, to average a value that is comparable to the rest of the exercise
// first second = value * 10
// second second = value * 5
// third second = value * 3.33
// et cetera

const MAX_INTENSITY_TIME = 10

const getIntensityValueForExerciseSecond = (
  value: number,
  exerciseSecond: number
) => {
  if (exerciseSecond >= MAX_INTENSITY_TIME) return value

  const multiplier = MAX_INTENSITY_TIME / exerciseSecond
  return value * multiplier
}

const evaluateIntensity = (intensity: number = 1) => {
  const n = getMax2Decimal(intensity)
  let zone: number = 1

  const zone2Start = 2
  const zone3Start = 4.75
  const zone4Start = 8.25
  const zone5Start = 13.25
  const zone6Start = 20.5
  const zone7Start = 33.25

  const checkZone = (zoneStart: number, zoneEnd: number) =>
    n >= zoneStart && n < zoneEnd

  switch (true) {
    case checkZone(zone2Start, zone3Start):
      zone = 2
      break
    case checkZone(zone3Start, zone4Start):
      zone = 3
      break
    case checkZone(zone4Start, zone5Start):
      zone = 4
      break
    case checkZone(zone5Start, zone6Start):
      zone = 5
      break
    case checkZone(zone6Start, zone7Start):
      zone = 6
      break
    case n >= zone7Start:
      zone = 7
      break
    default:
      zone = 1
      break
  }

  return zone
}

const calculateTotalIntensity = (
  filteredHits: INRWorkoutHit[] = [],
  weight: number
) => {
  const intensityPerHit = filteredHits?.map((hit) => hit.force / weight)
  const totalIntensity = intensityPerHit.reduce((a, b) => a + b, 0)

  return totalIntensity
}

export const getIntensityHitsExercise = ({
  started,
  seconds,
  hits,
}: INRExercise) => {
  const startTime = started ? new Date(started) : new Date()
  const intensityHits: INRWorkoutHit[][] = []

  for (let i = 1; i < seconds + 1; i += 1) {
    const second = startTime.getTime() + i * 1000
    const hitsLastTenSecond = getHitsLastTenSeconds(hits, second)
    intensityHits.push(hitsLastTenSecond)
  }

  return intensityHits
}

export const getHitsLastTenSeconds = (
  hits: INRWorkoutHit[],
  timestamp: number
) => {
  return hits.filter((hit) => {
    const timeDifference = timestamp - Number(hit.punchedAt)
    return timeDifference <= 10000 && timeDifference >= 0
  })
}

export const getEvaluatedIntensity = (
  hitsLastTenSeconds: INRWorkoutHit[],
  weight: number = 75,
  exerciseSecond: number
) => {
  weight = 75
  const totalIntensity = calculateTotalIntensity(hitsLastTenSeconds, weight)
  const intensityForExerciseSecond = getIntensityValueForExerciseSecond(
    totalIntensity,
    exerciseSecond
  )
  const evaluatedIntensity = evaluateIntensity(intensityForExerciseSecond)
  return evaluatedIntensity
}

export const getEvaluatedExerciseIntensity = (
  exercise: INRExercise,
  weight: number | null = 75
): number[] => {
  const effectiveWeight = getEffectiveWeight(weight)
  const intensityHits = getIntensityHitsExercise(exercise)
  return intensityHits.map((hits, i) =>
    getEvaluatedIntensity(hits, effectiveWeight, i + 1)
  )
}

export const getEvaluatedRoundIntensity = (
  round: INRRound,
  weight: number | null
): number[] => {
  const effectiveWeight = getEffectiveWeight(weight)
  const intensityPerExercise = round.exercises.flatMap((exercise) =>
    getEvaluatedExerciseIntensity(exercise, effectiveWeight)
  )

  return intensityPerExercise
}

export const getEvaluatedWorkoutIntensity = (
  workout: INRWorkout,
  weight: number | null
): number[] => {
  weight = 75
  const effectiveWeight = getEffectiveWeight(weight)
  const intensityPerRound = workout.rounds.flatMap((round) => {
    return getEvaluatedRoundIntensity(round, effectiveWeight)
  })

  return intensityPerRound
}

const getEffectiveWeight = (
  weight: number | null,
  defaultWeight: number = 75
): number => weight ?? defaultWeight

// Old functions to help calculate intensity for old workouts
export const getOldIntensityHits = (
  timedHits: INRWorkoutSessionTime,
  timePassed: number
) => {
  const intensityHits: INRWorkoutSessionTime = {}

  if (timePassed > 10) {
    for (const key in timedHits) {
      if (parseInt(key) <= timePassed && parseInt(key) >= timePassed - 10) {
        intensityHits[key] = timedHits[key]
      }
    }
  } else {
    for (const key in timedHits) {
      if (parseInt(key) <= timePassed) {
        intensityHits[key] = timedHits[key]
      }
    }
  }

  return intensityHits
}

export const getOldIntensityPerSecond = (
  weight: number = UserWeight.MEDIUM,
  timePassed: number,
  timedHits?: INRWorkoutHit[]
) => {
  const realTimedHits = convertHitsToSec(timedHits)
  const intensityHits = getOldIntensityHits(realTimedHits, timePassed)
  const convertedHits = convertHitsPerSec(intensityHits)
  const totalIntensity = calculateTotalIntensity(convertedHits, weight)
  const evaluatedIntensity = evaluateIntensity(totalIntensity)
  return evaluatedIntensity
}
export const getOldIntensityPerExercise = (
  exercise: INRExercise,
  weight: number = UserWeight.MEDIUM
) => {
  const intensityExercise: number[] = []

  for (let timePassed = 0; timePassed < exercise.seconds; timePassed++) {
    const intensityPerSecond = getOldIntensityPerSecond(
      weight,
      timePassed,
      exercise.hits
    )
    intensityExercise.push(intensityPerSecond)
  }

  return intensityExercise
}

export const getOldIntensityPerRound = (
  round: INRRound,
  weight: number = UserWeight.MEDIUM
): number[] =>
  round.exercises.flatMap((exercise) =>
    getOldIntensityPerExercise(exercise, weight)
  )

export const getOldIntensityPerWorkout = (
  workout: INRWorkout,
  weight: number = UserWeight.MEDIUM
): number[] =>
  workout.rounds.flatMap((round) => getOldIntensityPerRound(round, weight))
