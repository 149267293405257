import { INRWorkout } from './workouts'

export interface Follow {
  id: string
  state: any //TODO implement this
}

export interface INRMe {
  me: INRUser
}

export interface INRUser {
  weight?: number
  userName?: string | null
  id?: string
  email?: string
  name?: string
  gender?: Gender
  weightKg?: number
  privacyLevel?: PrivacyLevel
  pin?: string
  workouts?: INRWorkout[]
  followers?: Follow[]
  following?: Follow[]
}

export interface INRUserWithPassword extends INRUser {
  password?: string
}

export enum UserWeight {
  LIGHT = 55,
  MEDIUM = 75,
  HEAVY = 95,
}

export enum UserWeightLabel {
  LIGHT = 'Light',
  MEDIUM = 'Medium',
  HEAVY = 'Heavy',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Neutral = 'Neutral',
}

export enum PrivacyLevel {
  Myself = 'Myself',
  Followers = 'Followers',
  Public = 'Public',
}
