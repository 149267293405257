import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import useSignup from 'src/lib/auth/useSignup'
import Icon, { IconNames } from 'src/components/blocks/icons'
import ButtonLarge from 'src/components/blocks/button-large'
import Input from 'src/components/blocks/input/'
import SignupPopup from './signupPopup/'
import './style.scss'

const Signup = () => {
  const history = useHistory()
  const { handleSignupInput, handleSignup, error } = useSignup()
  const [isDisplayPopup, setDisplayPopup] = useState<boolean>(false)

  const signUp = () => {
    handleSignup()
    !error && setDisplayPopup(true)
  }

  const redirectWelcome = () => {
    history.push('/')
  }

  return (
    <div className="signup">
      <div className="signup__header">
        <Icon
          name={IconNames.LOGO_SMALL}
          size={50}
          customClickEvent={redirectWelcome}
        />
        <h1>Discover</h1>
        <div className="signup__header__nextround">NextRound</div>
      </div>
      <div className="signup__container">
        <div className="signup__input__container">
          <h4>Create account</h4>
          <div className="signup__input__wrapper">
            <Input
              name="email"
              inputType="text"
              spacingTop={true}
              placeholder="Email address"
              onChange={handleSignupInput}
            />
          </div>
          <ButtonLarge
            clickHandler={signUp}
            children={<h2>Create account</h2>}
          />
        </div>
        <div className="signup__error">{error && error}</div>
      </div>
      {isDisplayPopup && (
        <SignupPopup setDisplayPopup={() => setDisplayPopup(false)} />
      )}
    </div>
  )
}

export default Signup
