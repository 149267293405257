import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { WORKOUT } from 'src/graphql/queries'
import { INRWorkout } from 'src/types/workouts'
import useTabsPanel from 'src/hooks/useTabsPanel'
import TabsPanel from 'src/components/blocks/tabs-panel'
import WorkoutHeader from './workout-header'
import Overall from './overall'
import PerRound from './per-round'
import LeaderBoards from './leaderboards'
import Loader from 'src/components/blocks/loader'
import Error from 'src/components/blocks/error'
import { getFirstHighScoreChallengeWorkout } from 'src/lib/challenges'

interface WorkoutQuery {
  workout: INRWorkout[]
}

const WorkoutDetails = () => {
  const id = useParams<{ id: string }>()
  const { loading, error, data } = useQuery<WorkoutQuery>(WORKOUT, {
    variables: id,
  })

  const { activeTab, selectTab } = useTabsPanel()

  if (loading) return <Loader />

  if (error) return <Error>{error?.message ?? 'Error fetching workout'}</Error>

  const workoutData = data?.workout[0]
  const workoutTemplate = workoutData?.workoutTemplate
    ?.variants[0] as unknown as INRWorkout

  const workoutFirstHighScoreChallenge =
    getFirstHighScoreChallengeWorkout(workoutTemplate)
  const leaderboardId = workoutFirstHighScoreChallenge?.leaderboard?.id ?? ''

  const tabs = [
    { text: 'Overall' },
    { text: 'Per round' },
    ...(!!workoutFirstHighScoreChallenge
      ? [{ text: 'Leaderboards', isNew: true }]
      : []),
  ]

  const renderWorkoutsArr = [
    <Overall workoutData={workoutData!} />,
    <PerRound workoutData={workoutData} />,
    <LeaderBoards leaderboardId={leaderboardId} />,
  ]

  return (
    <>
      <WorkoutHeader
        name={workoutData?.name}
        id={id?.id}
        user={workoutData?.user}
        createdAt={workoutData?.createdAt}
      />
      <TabsPanel tabs={tabs} activeIndex={activeTab} handleClick={selectTab} />
      {renderWorkoutsArr[activeTab]}
    </>
  )
}

export default WorkoutDetails
