import './style.scss'

interface Props {
  name: string
  on: boolean
  notificationSetter: (setting: string) => void
}

const NotificationSetting = ({ name, on, notificationSetter }: Props) => {
  const weight = name === 'Notifications' ? 'bold' : 'normal'
  const toggleClass = on ? 'on' : 'off'

  return (
    <div className="single-notification">
      <div className={`single-notification__name--${weight}`}>
        <p className="large">{name}</p>
      </div>
      <div
        className={`single-notification__toggle single-notification__toggle--${toggleClass}`}
        onClick={() => {
          notificationSetter(name)
        }}
      >
        <div className="single-notification__toggle-button" />
      </div>
    </div>
  )
}

export default NotificationSetting
