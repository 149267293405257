import { INRStats } from 'src/types/stats'
import './style.scss'

type Props = Pick<INRStats, 'numOfHits' | 'totalForce' | 'maxSpeed'>

const PunchData = ({ numOfHits, totalForce, maxSpeed }: Props) => {
  return (
    <div className="punchData">
      <p>Punches</p>
      <p>Total Force</p>
      <p>Max. Speed</p>
      <p className="bold">{numOfHits}</p>
      <p className="bold">{Math.round(totalForce)} kg</p>
      <p className="bold">{maxSpeed} hits/m</p>
    </div>
  )
}

export default PunchData
