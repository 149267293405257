import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { IdQuery } from 'src/types/queries'
import { INRUser } from 'src/types/user'
import { MY_ID } from 'src/graphql/queries'
import { compareDate, getRoundedTimeUnitsFromDate } from 'src/lib/utilities'
import Icon, { IconNames } from 'src/components/blocks/icons/'
import './style.scss'

type Props = Pick<INRUser, 'id' | 'userName'> & {
  createdAt?: string
}

const User = ({ id = '', userName = '', createdAt = '' }: Props) => {
  const { data: meData } = useQuery<IdQuery>(MY_ID)
  const myId = meData?.me.id

  const diffDays = compareDate(createdAt)
  const dayQuantity = diffDays === 1 ? 'day' : 'days'
  const { hours, minutes } = getRoundedTimeUnitsFromDate(createdAt)

  return (
    <Link to={id === myId ? '/me' : `/profile/${id}`}>
      <div className="user">
        <div className="user__icon-container">
          <Icon name={IconNames.PROFILE_PIC} size={25} />
        </div>
        <div>
          <p>
            <b>{userName && userName}</b>
          </p>
          <p className="small">{`${diffDays} ${dayQuantity} ago, ${hours}:${minutes}`}</p>
        </div>
      </div>
    </Link>
  )
}

export default User
