import MoreSubject from 'src/components/blocks/more-subject'
import './style.scss'

const More = () => {
  return (
    <div className="more">
      <div className="more__options">
        <MoreSubject link="/disclaimer" text="Disclaimer" />
        <MoreSubject link="/faq" text="FAQ" />
        <MoreSubject link="/notifications" text="Notifications" />
        <MoreSubject link="/about" text="About" />
        {/* <MoreSubject link='/feedback' text='Feedback' /> */}
      </div>
      {/* <div className='more__footer'>
        <div className='more__footer-text'>
          <p>Follow us</p>
        </div>
        <div className='more__footer-icons'>
          <Icon name={IconNames.INSTAGRAM} size={32} />
          <Icon name={IconNames.FACEBOOK} size={32} />
          <Icon name={IconNames.TWITTER} size={32} />
        </div>
      </div> */}
    </div>
  )
}

export default More
