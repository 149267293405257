interface Props {
  icon?: JSX.Element
  property: string
  value: string
}

const DetailCardRow = ({ icon, property, value }: Props) => {
  const dark = property === 'Highest' || property === 'Lowest' ? 'dark' : ''
  return (
    <div className="detailCard__row">
      <div className="detailCard__row__icon">{icon && icon}</div>
      <p className={`detailCard__row__key ${dark}`}>{property}</p>
      <p className={`detailCard__row__value ${dark}`}>{value}</p>
    </div>
  )
}

export default DetailCardRow
