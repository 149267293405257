import { INRUserWithPassword } from 'src/types/user'

// TODO implement yup
export const checkEmptyField = (email: string, password: string) => {
  const mail_format =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  switch (true) {
    case !email || !password:
      return 'Please fill in all fields'
    case !mail_format.test(email):
      return 'Invalid email address'
    default:
      return ''
  }
}

export const checkEmptyFieldWithPassword = (
  { password }: INRUserWithPassword,
  passwordVerification: string
) => {
  const passwordFormat = /^(?=.*[A-Z])(?=.*\d).*$/

  switch (true) {
    case !password || !passwordVerification:
      return 'Please fill in password and password verification'
    case !passwordFormat.test(password ?? ''):
      return 'Password must contain at least one capital letter and one number'
    case password !== passwordVerification:
      return 'Your password and password verification do not match'
    default:
      return ''
  }
}

export const checkPasswordFormat = (
  password: string,
  confirmPassword: string
) => {
  const passwordFormat = /^(?=.*[A-Z])(?=.*\d).*$/

  switch (true) {
    case password && !passwordFormat.test(password ?? ''):
      return 'Password must contain at least one capital letter and one number'
    case password !== confirmPassword:
      return 'Your password and password verification do not match'
    default:
      return ''
  }
}

export const checkEmailFormat = (email: string) => {
  const mail_format =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  switch (true) {
    case !email:
      return 'Please fill in your email'
    case !mail_format.test(email):
      return 'Invalid email address'
    default:
      return ''
  }
}

export const handleInput =
  (setInputValues: React.Dispatch<React.SetStateAction<any>>) =>
  (evt: React.ChangeEvent<HTMLInputElement>) => {
    const name = evt.target.name
    const value = evt.target.value
    const newValue = valueIsNumber(name) ? parseInt(value) : value

    setInputValues((prev: any) => ({
      ...prev,
      [name]: newValue,
    }))
  }

export const handleAuthError =
  (setError: React.Dispatch<React.SetStateAction<string>>) =>
  (errMsg: string) => {
    setError(errMsg)
    let time = setTimeout(() => {
      setError('')
      clearTimeout(time)
    }, 5000)
  }

const valueIsNumber = (key: string) => (key === 'weightKg' ? true : false)
