import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { apiUrl } from '../src/config/constants'
import { setContext } from '@apollo/client/link/context'
import App from './App'

const httpLink = createHttpLink({
  uri: `https://${apiUrl}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('NEXTROUND_APP_TOKEN')
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
      'X-Authorization-Method': 'personal',
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  uri: `https://${apiUrl}`,
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
