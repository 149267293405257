import React from 'react'

import './style.scss'

const Disclaimer: React.FC = () => {
  return (
    <div className='disclaimer'>
      <h4>Disclaimer</h4>
      <br />
      <p>
        The user will indemnify and hold Sports-f(x) for, from and against, any claims or liabilities, including without limitation product
        liability claims, arising out of the use of Software, Hardware, Documentation, and parts thereof. This prototype is meant for
        research & development purposes. The product of Sports-f(x) is provided "AS IS," without a warranty of any kind. Sports-f(x)
        disclaims all responsibility for the accuracy or reliability of the Software, Hardware, and Documentation and does not warrant they
        will meet the user's requirements, be uninterrupted or error-free, or that any defects will be corrected. The entire risk arising
        out of use or performance of the Software, Hardware, and Documentation provided under this Agreement is assumed by the user.
      </p>
    </div>
  )
}

export default Disclaimer
