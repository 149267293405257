import SingleTab, { Tab } from './single-tab'
import './style.scss'

export interface Props {
  tabs: Omit<Tab, 'isActive' | 'handleClick'>[]
  activeIndex: number
  handleClick: (index: number) => void
}

const TabsPanel = ({ tabs, activeIndex, handleClick }: Props) => {
  const handleChangeActiveTab = (index: number) => () => handleClick(index)

  return (
    <div className="tabsPanel">
      {tabs.map((props, index) => {
        const isActive = activeIndex === index
        return (
          <SingleTab
            {...props}
            key={index}
            isActive={isActive}
            handleClick={handleChangeActiveTab(index)}
          />
        )
      })}
    </div>
  )
}

export default TabsPanel
