import gql from 'graphql-tag'

export const SIGNUP = gql`
  mutation signup($email: String!) {
    signup(email: $email)
  }
`

export const LOGIN = gql`
  mutation login($password: String!, $email: String!) {
    login(password: $password, email: $email)
  }
`

export const LOGIN_ORIGINAL_PASSWORD = gql`
  mutation loginOriginalPassword($password: String, $email: String) {
    login(password: $password, email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation changePasswordWithToken($token: String!, $newPassword: String!) {
    changePasswordWithToken(token: $token, newPassword: $newPassword)
  }
`

export const FORGOT_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`

export const VERIFY_EMAIL = gql`
  mutation verifyEmail(
    $verifyToken: String!
    $userName: String!
    $gender: Gender!
    $weightKg: Float
    $privacyLevel: PrivacyLevel!
    $password: String!
  ) {
    verifyEmail(
      input: {
        verifyToken: $verifyToken
        userName: $userName
        gender: $gender
        weightKg: $weightKg
        privacyLevel: $privacyLevel
        password: $password
      }
    )
  }
`

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String
    $userName: String
    $email: String
    $gender: Gender
    $weightKg: Float
    $privacyLevel: PrivacyLevel
  ) {
    updateUser(
      input: {
        id: $id
        userName: $userName
        email: $email
        gender: $gender
        weightKg: $weightKg
        privacyLevel: $privacyLevel
      }
    ) {
      id
    }
  }
`

export const ACCEPT = gql`
  mutation accept($followId: String) {
    accept(followId: $followId)
  }
`

export const DENY = gql`
  mutation deny($followId: String) {
    deny(followId: $followId)
  }
`

export const FOLLOW = gql`
  mutation follow($userId: String!) {
    follow(userId: $userId)
  }
`
export const UNFOLLOW = gql`
  mutation unfollow($followId: String!) {
    unfollow(followId: $followId)
  }
`

export const UPDATE_WORKOUT_RATING = gql`
  mutation updateWorkout($id: String!, $rating: Int!) {
    updateWorkout(input: { id: $id, rating: $rating }) {
      id
      rating
    }
  }
`

export const UPDATE_WORKOUT_NOTES = gql`
  mutation updateWorkout($id: String!, $notes: String!) {
    updateWorkout(input: { id: $id, notes: $notes }) {
      id
      notes
    }
  }
`

export const CLAP = gql`
  mutation clap($workoutId: String!) {
    clap(workoutId: $workoutId)
  }
`

export const CONNECTWORKOUT = gql`
  mutation connectWorkout($workoutId: String!) {
    connectWorkout(workoutId: $workoutId)
  }
`

export const CREATE_LEADERBOARD = gql`
  mutation createLeaderboard($challengeId: String!) {
    createLeaderboard(input: { challenge: { id: $challengeId } }) {
      id
    }
  }
`

export const CREATE_LEADERBOARD_RECORD = gql`
  mutation createLeaderboardRecord(
    $score: Float!
    $userId: String!
    $leaderboardId: String!
  ) {
    createLeaderboardRecord(
      input: {
        score: $score
        user: { id: $userId }
        leaderboard: { id: $leaderboardId }
      }
    ) {
      id
      score
    }
  }
`

export const UPDATE_LEADERBOARD_RECORD = gql`
  mutation updateLeaderboardRecord(
    $score: Float!
    $recordId: String!
    $userId: String!
  ) {
    updateLeaderboardRecord(
      input: { score: $score, id: $recordId, user: { id: $userId } }
    ) {
      id
    }
  }
`
