export const questionData = [
  {
    title: 'Profile',
    questions: [
      {
        text: 'How can I add friends?',
        tooltip: (
          <div>
            You can add friends by sending them your profile as an invite, or let them scan a QR code. Go to your profile page and click
            Follower or Following in the upper right corner. Then click on invite. Here you can copy the link to your profile or find your
            personal QR code.
          </div>
        ),
      },
      {
        text: 'How can I change my password?',
        tooltip: <div>To change your password, go to your profile page, and click ‘Edit profile’. Here you can change your password.</div>,
      },
      {
        text: 'Where can I find my login code?',
        tooltip: (
          <div>
            You can find your personal Next Round login code in your profile section. Go to the profile page, click ‘Edit profile’ and
            scroll down. On the bottom of the page you will find the button ‘Login code’.
          </div>
        ),
      },
      {
        text: 'Why do I need a username?',
        tooltip: (
          <div>
            Your username is your identification. It's used to build a unique digital profile about you specifically. To manage privacy
            right, we let you choose your own username.
          </div>
        ),
      },
      {
        text: 'Why are my gender and weight asked?',
        tooltip: (
          <div>
            This info is used to give you personalized feedback (for example relative impact & calorie usage) and to compare yourself with
            people that are in the same weightclass
          </div>
        ),
      },
    ],
  },
  {
    title: 'Workouts',
    questions: [
      {
        text: 'Where can I find my latest results?',
        tooltip: (
          <div>
            Click the NR logo in the upper right corner. This will directly bring you to your overview page. Latest results show at the top!
            You can also use the screen icon in the bottom left.
          </div>
        ),
      },
      {
        text: 'How do I know if my results are saved?',
        tooltip: (
          <div>
            After your workout, choose Save your results. Follow the instructions and see your results appear in your workout overview in
            the web app.
          </div>
        ),
      },
      {
        text: 'Do I need to do a warming-up first?',
        tooltip: <div>We always recommend to warm-up before a workout and cool-down after a workout</div>,
      },
      {
        text: 'When will new workouts be uploaded?',
        tooltip: (
          <div>
            The current product is a prototype, so we are constantly working on a better version and are aiming for regular updates and
            add-ons to keep creating more value. Please contact us if you would like to help!
          </div>
        ),
      },
      {
        text: 'Can I create my own workouts?',
        tooltip: (
          <div>
            You are able to create a 'Free-style' training that makes it possible to adjust the amount of rounds, the minutes per round and
            the time to rest in between those rounds.
          </div>
        ),
      },
      {
        text: 'How does multiplayer work?',
        tooltip: (
          <div>
            The multiplayer option enables you to share the workout with a friend. You will do exercises while your friend is free to do
            bodyweight exercises, hype you up, or take a break! After your Round, it is your friends turn. Who will have the better scores?
          </div>
        ),
      },
      {
        text: 'I would like to help with the development, how can I apply?',
        tooltip: (
          <div>
            Awesome, thank you so much! We are always looking for enthousiastic people that can help us developing an even better
            experience. Please send an email to info@nextroundboxing.com and we will get back to you quickly.
          </div>
        ),
      },
    ],
  },
  {
    title: 'Measurements',
    questions: [
      {
        text: 'By what metric is impact force measured?',
        tooltip: <div>Kilogram force (kgf)</div>,
      },
      {
        text: 'How accurate are the measurements?',
        tooltip: (
          <div>
            The NextRound system is created at the Human Movement Science departement at the Vrije Universiteit in Amsterdam and is designed
            to give the highest accuracy of punches and kicks you can possibly get out of a swinging boxing bag.
          </div>
        ),
      },
    ],
  },
  {
    title: 'Data & Privacy',
    questions: [
      {
        text: 'What do you do with my personal data?',
        tooltip: (
          <div>
            We use your information to improve our software. Of course all data is anonymised. We will contact via your email address
            though, if there are any updates, or notifications.
          </div>
        ),
      },
      {
        text: 'Where is my workout info saved?',
        tooltip: (
          <div>
            We save and protect your data in the cloud. Because of this you can start a training and access your workout-data anywhere and
            anytime.
          </div>
        ),
      },
      {
        text: 'I forgot my password, and now?',
        tooltip: <div>You will be able to reset your password in an upcoming feature. Coming soon!</div>,
      },
      {
        text: 'Who can see my data?',
        tooltip: (
          <div>
            You are free to choose which data you want to share with whom. You are able to adjust these settings in your personal profile.
            You can choose between Everyone, Friends and only you.
          </div>
        ),
      },
    ],
  },
  {
    title: 'Other',
    questions: [
      {
        text: 'Is the app available on iOS or Android?',
        tooltip: <div>For now, we chose for a WebApp, so everybody with WiFi is able to use and try-out the NextRound invention. </div>,
      },
      {
        text: 'Can I connect with other Apps?',
        tooltip: <div>Not yet, but that would be awesome! We are working on it!</div>,
      },
    ],
  },
]
