import { INRUser } from './user'

export enum RoundModes {
  TRYOUT = 'tryout',
  WORKOUT = 'workout',
}

export enum PlayerNames {
  PLAYER_1 = 'player1',
  PLAYER_2 = 'player2',
}

export enum DifficultyLevel {
  STARTER = 'Starter',
  EXPERIENCED = 'Experienced',
  PRO = 'Pro',
}

export enum WorkoutType {
  KICKBOXING = 'Kickboxing',
  BOXING = 'Boxing',
}

export enum WorkoutName {
  FREESTYLE = 'Freestyle',
  QUICKSTART = 'Quickstart',
}

export interface INRconfig {
  dualPlayer: boolean
  activeRound: number
  activeWorkOut: boolean
  activeMode: RoundModes
  sound: boolean
}

export interface INRImage {
  downloadLocation: string
}

export interface INRWorkoutHit {
  angle: number
  force: number
  height: number
  punchedAt: string
}

export interface INRWorkoutSessionTime {
  [second: number]: INRWorkoutHit[]
}

export enum ConstraintName {
  Hits = 'Hits',
  AvgForce = 'AvgForce',
  TotalForce = 'TotalForce',
  AvgIntensity = 'AvgIntensity',
}

export interface INRWorkoutConstraint {
  max: number | null
  min: number | null
  showInUI: boolean
  type: ConstraintName
}

export interface INRWorkoutChallenge {
  id?: string
  title: string
  description: string
  failMessage: string
  successMessage: string
  constraints: INRWorkoutConstraint[]
  leaderboard?: INRLeaderboard
}

export interface INRWorkoutVariant {
  difficultyLevel: DifficultyLevel
  rounds: INRRound[]
}

export interface INRMove {
  name: string
}

export interface INRExercise {
  seconds: number
  videoUrl?: string | null
  challenges?: INRWorkoutChallenge[] | null
  breakSeconds: number
  calories: number
  image?: INRImage
  moves?: INRMove[]
  hits: INRWorkoutHit[]
  started: Date | null
}

export interface INRRound {
  countdownSeconds: number
  breakSeconds: number
  exercises: INRExercise[]
  videoUrl?: string | null
  image?: INRImage | null
  round?: number
  duration: number
  numOfHits: number
  totalForce: number
  maxForce: number
  minForce: number
  avgForce: number
  avgSpeed: number
  maxSpeed: number
  minSpeed: number
}

export interface INRWorkout {
  id: string
  image?: INRImage | null
  overviewImage?: INRImage | null
  rank: number | null
  name?: string
  rounds: INRRound[]
  videoUrl?: string | null
  workoutType: WorkoutType
  user?: INRUser
  createdAt?: string
  notes?: string
  rating?: number
  claps?: INRClap[]
  highlights?: {
    text: string
  }[]
  workoutTemplate?: INRWorkoutTemplate
  duration: number
  numOfHits: number
  totalForce: number
  maxForce: number
  minForce: number
  avgForce: number
  avgSpeed: number
  maxSpeed: number
  minSpeed: number
}

export interface INRWorkoutTemplate {
  id: string
  image?: INRImage | null
  rounds?: INRRound[]
  overviewImage?: INRImage | null
  rank: number | null
  name: string
  duration: number | null
  variants: INRWorkoutVariant[]
  videoUrl?: string | null
  workoutType: WorkoutType
}

export interface INRWworkoutCategory {
  id: string
  name: string
  rank: number | null
  templates: INRWorkoutTemplate[]
}

export interface INRClap {
  id?: string
  user: INRUser
}

export interface INRLeaderboard {
  id: string
  records: INRLeaderboardRecord[]
}

export interface INRLeaderboardRecord {
  score: number
  user: INRUser
}

export interface INRLeaderboardRecordForUser {
  leaderboardRecord: INRLeaderboardRecord
  offset: number
}
