import { Link, useHistory } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client'

import { UserNameAndWorkouts } from 'src/types/queries'
import { MY_USERNAME_AND_WORKOUTS } from 'src/graphql/queries'
import WorkoutCard from 'src/components/blocks/workout-card'
import ProfileCard from 'src/components/blocks/profile-card'
import Button from 'src/components/blocks/button'
import Icon, { IconNames } from 'src/components/blocks/icons'
import Loader from 'src/components/blocks/loader'
import './style.scss'
import Error from '../error'

const ProfileContent = () => {
  const client = useApolloClient()
  const history = useHistory()
  const { data, loading, error } = useQuery<UserNameAndWorkouts>(
    MY_USERNAME_AND_WORKOUTS
  )
  const me = data?.me

  const logOut = () => {
    localStorage.removeItem('NEXTROUND_APP_TOKEN')
    client.clearStore()
    history.push('/welcome')
  }

  if (error) return <Error>{error?.message ?? 'Error fetching profile'}</Error>

  if (loading) return <Loader />

  return (
    <div className="profile">
      <div className="profile__header">
        <div>
          <Icon name={IconNames.PROFILE_PIC} size={35} />
          <p className="large bold">{me?.userName && me.userName}</p>
        </div>
      </div>
      <ProfileCard workouts={me?.workouts} />
      <h5>My Workouts</h5>
      <div className="profile__workouts">
        {me?.workouts?.length ? (
          [...me.workouts].reverse().map((workout, i) => {
            if (workout.user) return <WorkoutCard key={i} {...workout} />
          })
        ) : (
          <p>No workouts yet!</p>
        )}
      </div>
      <div className="profile__btnWrapper">
        <Link to="edit-profile">
          <Button
            buttonType="secondary"
            children="Edit profile"
            cn="profile__editProfileBtn"
          />
        </Link>
        <Button
          clickHandler={logOut}
          children="Log out"
          cn="profile__logoutBtn"
        />
      </div>
    </div>
  )
}

export default ProfileContent
