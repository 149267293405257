import { INRWorkout } from 'src/types/workouts'
import Icon, { IconNames } from 'src/components/blocks/icons'
import './note.scss'

type Props = Pick<INRWorkout, 'notes'> & {
  setDisplayNote: (setDisplayNote: boolean) => void
}

const Note = ({ setDisplayNote, notes = '' }: Props) => {
  return (
    <div className="note">
      <p className="small bold note__title">Notes</p>
      <div
        className="note__icon"
        onClick={() => {
          setDisplayNote(true)
        }}
      >
        <Icon name={IconNames.NOTE} size={12} />
      </div>
      <p
        className="small note__add"
        onClick={() => {
          setDisplayNote(true)
        }}
      >
        <u>{notes ? notes : 'Add a note'}</u>
      </p>
    </div>
  )
}

export default Note
