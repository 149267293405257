import { getYaxisTicks } from 'src/lib/graph'
import './style.scss'
import AvgLine from './avg-line'

interface Props {
  maxForce: number
  isForceGraph: boolean
  isSingleRound?: boolean
  avg: number
}

const CustomYAxis = ({ maxForce, isForceGraph, isSingleRound, avg }: Props) => {
  const { firstTick, secondTick, thirdTick, fourthTick, fifthTick } =
    getYaxisTicks(maxForce)

  const ticks = isForceGraph
    ? [0, firstTick, secondTick, thirdTick, fourthTick, fifthTick]
    : [0, 1, 2, 3, 4, 5, 6, 7]


  const renderTicks = ticks
    .reverse()
    .map((tick: number | null, index: number) => (
      <div className="roundsGraph__y-axis__tick" key={index}>
        <p className="light">{tick}</p>
        {(!isForceGraph || index !== 5) && (
          <hr className="roundsGraph__y-axis__line " />
        )}
      </div>
    ))

  return (
    <div className="roundsGraph__y-axis__wrapper">
      <AvgLine value={avg} />
      {renderTicks}
    </div>
  )
}

export default CustomYAxis
