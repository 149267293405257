import React from 'react'

import { ButtonType } from 'src/types/blocks'
import './style.scss'

interface Props {
  clickHandler?: () => void
  children: React.ReactElement | React.ReactNode | string
  cn?: string
  buttonType?: ButtonType
}

const ButtonLarge = ({
  clickHandler,
  children,
  cn = '',
  buttonType = 'primary',
}: Props) => {
  return (
    <button
      className={`nextroundBtnLg nextroundBtnLg__${buttonType} ${cn}`}
      onClick={clickHandler}
    >
      {children}
    </button>
  )
}

export default ButtonLarge
