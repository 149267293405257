import {
  INRExercise,
  INRRound,
  INRWorkout,
  INRWorkoutHit,
} from '../types/workouts'
import { sumUp } from './utilities'
import { INRStats } from '../types/stats'

// These functions should be aligned with the training app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

export const getTotalForce = (hits: INRWorkoutHit[]): number => {
  return hits.map((hit) => hit.force).reduce((a, b) => a + b, 0)
}

export const getMaxForce = (hits: INRWorkoutHit[]): number => {
  if (!hits.length) return 0
  return Math.max(...hits.map((hit) => hit.force))
}

export const getMinForce = (hits: INRWorkoutHit[]): number => {
  if (!hits.length) return 0
  return Math.min(...hits.map((hit) => hit.force))
}

export const getAvgForce = (hits: INRWorkoutHit[]): number => {
  if (!hits.length) return 0
  return getTotalForce(hits) / hits.length
}

export const getRoundDuration = (round: INRRound): number =>
  round.exercises
    .flatMap((exercise) => exercise.seconds)
    .reduce((a, b) => a + b, 0)

export const getWorkoutDuration = (workout: INRWorkout): number =>
  workout.rounds
    .flatMap((round) => getRoundDuration(round))
    .reduce((a, b) => a + b, 0)

export const getExerciseHits = (exercise: INRExercise): INRWorkoutHit[] =>
  exercise.hits ?? []

export const getRoundHits = (round: INRRound): INRWorkoutHit[] =>
  round.exercises.flatMap(getExerciseHits)

export const getWorkoutHits = (workoutSession: INRWorkout): INRWorkoutHit[] => {
  return workoutSession.rounds.flatMap(getRoundHits)
}

export const calcAvgSpeed = (numOfHits: number, duration: number): number => {
  return (numOfHits * 60) / duration
}

export const calcAvgSpeedPerExercise = (exercise: INRExercise): number => {
  const exerciseDuration = exercise.seconds
  const exerciseHits = getExerciseHits(exercise)
  return calcAvgSpeed(exerciseHits.length, exerciseDuration)
}

export const getAvgSpeedExerciseArray = (round: INRRound): number[] =>
  round.exercises.map(calcAvgSpeedPerExercise)

export const calcAvgSpeedPerRound = (round: INRRound): number => {
  const roundDuration = getRoundDuration(round)
  const roundHits = getRoundHits(round)
  return calcAvgSpeed(roundHits.length, roundDuration)
}

export const getAvgSpeedRoundArray = (workout: INRWorkout): number[] =>
  workout.rounds.map(calcAvgSpeedPerRound)

export const getRoundStats = (round: INRRound): INRStats => {
  const roundHits = getRoundHits(round)
  const duration = getRoundDuration(round)
  const avgSpeedExercises = getAvgSpeedExerciseArray(round)

  const totalSpeed = sumUp(avgSpeedExercises)
  const avgSpeed = Math.round(totalSpeed / avgSpeedExercises.length)
  const maxSpeed = Math.round(Math.max(...avgSpeedExercises))
  const minSpeed = Math.round(Math.min(...avgSpeedExercises))

  const totalForce = getTotalForce(roundHits)
  const avgForce = Math.round(getAvgForce(roundHits))
  const maxForce = Math.round(getMaxForce(roundHits))
  const minForce = Math.round(getMinForce(roundHits))

  return {
    duration,
    numOfHits: roundHits.length,
    totalForce,
    maxForce,
    minForce,
    avgForce,
    avgSpeed,
    maxSpeed,
    minSpeed,
  }
}

export const getWorkoutStats = (workout: INRWorkout): INRStats => {
  const workoutHits = getWorkoutHits(workout)
  const duration = getWorkoutDuration(workout)
  const avgSpeedRounds = getAvgSpeedRoundArray(workout)

  const totalSpeed = sumUp(avgSpeedRounds)
  const avgSpeed = Math.round(totalSpeed / avgSpeedRounds.length)
  const maxSpeed = Math.round(Math.max(...avgSpeedRounds))
  const minSpeed = Math.round(Math.min(...avgSpeedRounds))

  const totalForce = getTotalForce(workoutHits)
  const avgForce = Math.round(getAvgForce(workoutHits))
  const maxForce = Math.round(getMaxForce(workoutHits))
  const minForce = Math.round(getMinForce(workoutHits))

  return {
    duration,
    numOfHits: workoutHits.length,
    totalForce,
    maxForce,
    minForce,
    avgForce,
    avgSpeed,
    maxSpeed,
    minSpeed,
  }
}

export const getAllWorkoutsStats = (workouts: INRWorkout[]): INRStats => {
  const allWorkoutHits = workouts.flatMap(getWorkoutHits)
  const duration = sumUp(workouts.flatMap(getWorkoutDuration))
  const avgSpeedWorkouts = workouts.flatMap(getAvgSpeedRoundArray)

  const totalSpeed = sumUp(avgSpeedWorkouts)
  const avgSpeed = Math.round(totalSpeed / avgSpeedWorkouts.length)
  const maxSpeed = Math.round(Math.max(...avgSpeedWorkouts))

  const totalForce = getTotalForce(allWorkoutHits)
  const avgForce = Math.round(getAvgForce(allWorkoutHits))
  const maxForce = Math.round(getMaxForce(allWorkoutHits))
  const minForce = Math.round(getMinForce(allWorkoutHits))

  return {
    duration,
    numOfHits: allWorkoutHits.length,
    totalForce,
    maxForce,
    minForce,
    avgForce,
    avgSpeed,
    maxSpeed,
    minSpeed: 0,
  }
}
