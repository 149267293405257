import {
  ConstraintName,
  INRExercise,
  INRRound,
  INRWorkout,
  INRWorkoutChallenge,
  INRWorkoutConstraint,
} from 'src/types/workouts'
import { getAverage } from './utilities'
import { getAvgForce, getTotalForce } from './stats'
import { getEvaluatedExerciseIntensity } from './intensity'
import { UserWeight } from 'src/types/user'


// These functions should be aligned with the training app as they should used to calculate the user's score exactly the same way
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps

// In the data model, an exercise can have N amount of challenges. Design only allows for one challenge per exercise.
// On the workout complete screen, when challenges are completed, design only allows for about four challenge to be displayed at a time.
// "High score games", or "high score challenges" are technically just any challenges that don't have a min or max constraint. These constraints are filled in in the backoffice.
// The max of a constraint represents the maximum amount a user may reach within that challenge.
// The min of a constraint represents the minimum amount a user must reach within that challenge.
// The result of a challenge, compared against a constraint determines if the challenge was successful or not.
// In the data model a challenge can have N amount of constraints. Design only allows for one constraint per challenge.

export const getChallengesExercise = (
  exercise: INRExercise
): INRWorkoutChallenge[] => exercise.challenges || []

export const getChallengesRound = (round: INRRound): INRWorkoutChallenge[] =>
  round.exercises.flatMap(getChallengesExercise)

export const getChallengesWorkout = (
  workout: INRWorkout
): INRWorkoutChallenge[] => workout.rounds.flatMap(getChallengesRound)

export const getAllHighScoreChallengesWorkout = (
  workout: INRWorkout
): INRWorkoutChallenge[] => {
  return getChallengesWorkout(workout).filter(checkIsHighScoreChallenge)
}
export const getFirstHighScoreChallengeWorkout = (
  workout?: INRWorkout
): INRWorkoutChallenge | null => {
  if (!workout) return null
  const highScoreChallenges = getAllHighScoreChallengesWorkout(workout)
  return highScoreChallenges.length ? highScoreChallenges[0] : null
}

export const findFirstChallengeInExercise = (
  exercise: INRExercise
): INRWorkoutChallenge | null =>
  !!exercise.challenges && exercise.challenges.length
    ? exercise.challenges[0]
    : null

export const findFirstChallengeInRound = (
  round: INRRound
): INRWorkoutChallenge | null =>
  round.exercises
    .flatMap(findFirstChallengeInExercise)
    .find((challenge) => !!challenge) || null

export const findFirstExerciseWithChallengeInRound = (
  round: INRRound
): INRExercise | null =>
  round.exercises.find(
    (exercise) => !!exercise.challenges && exercise.challenges.length
  ) || null

export const findExerciseWithChallenge = (round: INRRound): any | null => {
  const exerciseWithChallenge = round.exercises.find(
    (exercise) => !!exercise.challenges && exercise.challenges.length
  )
  return !!exerciseWithChallenge ? exerciseWithChallenge : null
}

export const checkIfExerciseHasChallenges = (exercise: INRExercise): boolean =>
  !!exercise.challenges && exercise.challenges.length ? true : false

export const checkIfRoundHasChallenges = (round: INRRound): boolean =>
  round.exercises.some(checkIfExerciseHasChallenges)

export const checkIfWorkoutHasChallenges = (workout: any): boolean =>
  workout.rounds.some(checkIfRoundHasChallenges)

export const getDisplayChallenge = (exercise: INRExercise) => {
  const exerciseChallenge = getExerciseChallenge(exercise)
  const challengeConstraint = getChallengeConstriant(exerciseChallenge)
  return challengeConstraint?.showInUI
}

export const getExerciseChallenge = (exercise: INRExercise) =>
  exercise.challenges?.[0] // an exercise only handles the first challenge in design

export const getChallengeConstriant = (challenge?: INRWorkoutChallenge) =>
  challenge?.constraints?.[0]! // a challenge only handles the first constraint in design

export const checkIsHighScoreChallenge = (challenge?: any): boolean => {
  const constraint = getChallengeConstriant(challenge)
  if (!constraint) return false
  return !constraint.max && !constraint.min
}

const evaluateMinMax = (
  value: number,
  min: number | null,
  max: number | null
) => {
  if (!!max && !!min) return value >= min && value <= max
  if (!!max && !min) return value <= max
  if (!max && !!min) return value >= min
  return false
}
export const getEvaluatedConstraint = (
  exercise: INRExercise,
  constraint: INRWorkoutConstraint,
  weight: number = UserWeight.MEDIUM
) => {
  const result = getConstraintResult(exercise, constraint, weight)
  const isSuccess = evaluateMinMax(result, constraint.min, constraint.max)
  const isHighScore = !constraint.max && !constraint.min
  const showSuccess = isSuccess || isHighScore
  const displayMin = constraint.min ? constraint.min : Math.round(result)
  const displayMax = constraint.max ? constraint.max : Math.round(result)

  return {
    ...constraint,
    isSuccess,
    isHighScore,
    showSuccess,
    displayMin,
    displayMax,
    result,
  }
}

export const getConstraintResult = (
  exercise: INRExercise,
  constraint: INRWorkoutConstraint,
  weight: number
) => {
  switch (constraint?.type) {
    case ConstraintName.AvgForce:
      return getAvgForce(exercise.hits)
    case ConstraintName.Hits:
      return exercise.hits.length
    case ConstraintName.TotalForce:
      return getTotalForce(exercise.hits)
    case ConstraintName.AvgIntensity:
      return getAverage(getEvaluatedExerciseIntensity(exercise, weight))
    default:
      return 0
  }
}
