import { INRExercise, INRRound, INRWorkoutHit } from '../types/workouts'
import { convertHitsToTimedHits } from './utilities'

// These functions should be aligned with the training app
// In the future, we might want to build a shared library for these functions, also when we expand to group app or additional apps
export const getAverageForcePercent = (allHits: INRWorkoutHit[]) => {
  const forceHits = allHits.map((hit) => hit.force)
  const maxForce = Math.round(Math.max(...forceHits))
  const avgForce = calcAverageForce(allHits)
  const avgForcePercent = Math.round((avgForce / maxForce) * 100)

  return !isNaN(avgForcePercent) ? avgForcePercent : 0
}

export const getYaxisTicks = (maxForce: number) => {
  const maxForceExists = isFinite(maxForce)

  return {
    firstTick: maxForceExists ? Math.round((maxForce / 100) * 20) : null,
    secondTick: maxForceExists ? Math.round((maxForce / 100) * 40) : null,
    thirdTick: maxForceExists ? Math.round((maxForce / 100) * 60) : null,
    fourthTick: maxForceExists ? Math.round((maxForce / 100) * 80) : null,
    fifthTick: maxForceExists ? Math.round(maxForce) : null,
  }
}

export const getXaxisTicks = (firstValue: number, lastValue: number) => {
  const secondValue = firstValue + ((lastValue - firstValue) / 100) * 25
  const thirdValue = firstValue + ((lastValue - firstValue) / 100) * 50
  const fourthValue = firstValue + ((lastValue - firstValue) / 100) * 75

  return { secondValue, thirdValue, fourthValue }
}

export const getRoundBarsData = (round: INRRound) =>
  round.exercises.flatMap(getExerciseBarsData)

export const getExerciseBarsData = ({
  seconds,
  hits,
  started,
}: INRExercise) => {
  let start = 0
  const barsPerSecond = []
  const timedHits = convertHitsToTimedHits(hits, started)

  while (start <= seconds) {
    let bars: { force: number | null }[] = []
    if (timedHits[start]) {
      bars = timedHits[start].map((hit) => ({ force: hit.force }))
    } else {
      bars = [{ force: null }]
    }

    barsPerSecond.push(bars)
    start++
  }

  return barsPerSecond.flat()
}

export const getOldRoundBarsData = (round: INRRound) => round.exercises.flatMap(getOldExerciseBarsData)

export const getOldExerciseBarsData = (exercise: INRExercise) => exercise.hits.map((hit) => ({ force: hit.force }))

export const getForceGraphData = (barsArray: { force: number | null }[]) => {
  const forceGraphData = barsArray.map((hit, index: number) => ({
    force: hit.force,
    index,
  }))
  return forceGraphData
}

export const calcAverageForce = (allHits: INRWorkoutHit[]) => {
  let total: number = 0
  allHits.forEach((hit) => (total += hit.force))
  return Math.round(total / allHits.length)
}
