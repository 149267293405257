import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { useApolloClient, useMutation } from '@apollo/client'

import {
  CONNECTWORKOUT,
  CREATE_LEADERBOARD,
  CREATE_LEADERBOARD_RECORD,
  UPDATE_LEADERBOARD_RECORD,
} from 'src/graphql/mutations'
import Error from 'src/components/blocks/error'
import { CHALLENGE_TEMPLATE, ME, WORKOUT } from 'src/graphql/queries'
import { getExercisesFromWorkout } from 'src/lib/utilities'
import { PrivacyLevel, UserWeight } from 'src/types/user'
import {
  checkIfExerciseHasChallenges,
  checkIsHighScoreChallenge,
  getChallengeConstriant,
  getEvaluatedConstraint,
  getExerciseChallenge,
} from 'src/lib/challenges'
import Loader from 'src/components/blocks/loader'

// This page can be accessed after a workout is saved in the training app
// The QR-code from the training app adds the workout id to the url redirecting the user to the personal app
// On this page, the ID is used to query the workout and its template, connect it to the logged in user and create leaderboard(records) is needed
const SaveWorkout = () => {
  const client = useApolloClient()
  const history = useHistory()
  const param = useParams<{ id: string }>()
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const [connectWorkout] = useMutation(CONNECTWORKOUT, {
    onError: (err) => {
      setError(err.message)
    },
    onCompleted: () => {
      history.push('/activity-feed')
    },
  })

  useEffect(() => {
    setLoading(true)

    // some acrobatics to save the leaderboard. Need to query into the workout's template to get the challenges/constraints (these only exist on the template) and add those to the workout's exercises/hits
    // So when the workout is saved from the training app, it is crucial that this workout is connected to its template
    // We have to use these challenges/constraints together with the hits from the workout itself to calculate the user's score
    // The score is then saved in the leaderboard (record)
    // many of these types are any because intensity calculation is not yet implemented, which will simplify and change the types throughout both apps
    const saveLeaderboard = async () => {
      try {
        const { data: me } = await client.query({
          query: ME,
        })

        const { data: workout } = await client.query({
          query: WORKOUT,
          variables: { id: param.id },
        })

        // getting an array of all exercises templates with challenges
        const workoutTemplateData = workout.workout[0].workoutTemplate.variants[0]
        const exerciseTemplates = getExercisesFromWorkout(workoutTemplateData)
        const exerciseTemplatesWithChallenges = exerciseTemplates.filter(
          checkIfExerciseHasChallenges
        )
        const exercisesTemplateWithHighScoreChallenges = exerciseTemplatesWithChallenges.filter(
          (exercise) =>
            checkIsHighScoreChallenge(getExerciseChallenge(exercise))
        )

        // getting an array of all exercises with challenges
        const workoutData = workout.workout[0]
        const exercises = getExercisesFromWorkout(workoutData)
        const exercisesWithChallenges = exercises.filter(checkIfExerciseHasChallenges)
        const exercisesWithHighScoreChallenges = exercisesWithChallenges.filter(
          (exercise) =>
            checkIsHighScoreChallenge(getExerciseChallenge(exercise))
        )

        const { id, privacyLevel, email, weightKg } = me.me

        if (privacyLevel !== PrivacyLevel.Public) return

        for (let i = 0; i < exercisesTemplateWithHighScoreChallenges.length; i++) {
          const exerciseTemplate = exercisesTemplateWithHighScoreChallenges[i];
          const exercise = exercisesWithHighScoreChallenges[i]
          const challengeTemplate = getExerciseChallenge(exerciseTemplate)
          const constraint = getChallengeConstriant(challengeTemplate)

          // The challenge and its constraints only exist in the template
          // The challenge results are calculated based on the user's actual hits, which exist in the exercise but not in the template
          const { result: score } = getEvaluatedConstraint(
            exercise,
            constraint,
            weightKg ?? UserWeight.MEDIUM
          )

          const challengeId = challengeTemplate?.id ?? ''

          const { data } = await client.query({
            query: CHALLENGE_TEMPLATE,
            variables: { id: challengeId },
          })

          const leaderboard = data.challengeTemplate[0]?.leaderboard

          if (!leaderboard) {
            // If there is no leaderboard yet, we create one, then add the user's record to it
            const { data: createLeaderBoardData } = await client.mutate({
              mutation: CREATE_LEADERBOARD,
              variables: { challengeId, score, userEmail: email },
            })

            const leaderboardId = createLeaderBoardData.createLeaderboard.id

            await client.mutate({
              mutation: CREATE_LEADERBOARD_RECORD,
              variables: { score, userId: id, leaderboardId },
            })
            return
          }

          const userRecord = leaderboard.records.find(
            (record: any) => record.user.id === id
          )

          if (!!userRecord) {
            if (score < userRecord.score) return
            // If the user already has a record, we update it only if the new score is higher
            const userRecordId = userRecord?.id ?? ''

            await client.mutate({
              mutation: UPDATE_LEADERBOARD_RECORD,
              variables: { score, recordId: userRecordId, userId: id },
            })

            return
          }

          // if there is a leaderboard, but the user doesn't have a record, we add one
          await client.mutate({
            mutation: CREATE_LEADERBOARD_RECORD,
            variables: { score, userId: id, leaderboardId: leaderboard.id },
          })

          return
        }
      } catch (err) {
        setError((err as Error).message)
      }
    }

    saveLeaderboard()

    // simple mutation linking userId to workoutId
    connectWorkout({ variables: { workoutId: param.id } })
    setLoading(false)
  }, [client, connectWorkout, param.id])

  if (loading) return <Loader />
  if (error) return <Error>{error}</Error>

  return <p>Connecting workout...</p>
}

export default SaveWorkout
