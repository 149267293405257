import React from 'react'
import { useHistory } from 'react-router-dom'

import ButtonLarge from 'src/components/blocks/button-large'
import './style.scss'

const NoWorkouts: React.FC = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/invite')
  }

  return (
    <div className="no-workouts">
      <div className="no-workouts__title">No workouts yet!</div>
      <div className="no-workouts__text">
        Start your first workout on a <b>NextRound</b> device to see result
        here.
        <br />
        <span> or</span>
      </div>
      <ButtonLarge
        children={<p className="inviteButton">Invite training buddies</p>}
        clickHandler={handleClick}
      />
    </div>
  )
}

export default NoWorkouts
