import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { SIGNUP } from 'src/graphql/mutations'
import { checkEmailFormat, handleAuthError } from './helpers'

const useSignup = () => {
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const handleSignupInput = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(evt.target.value)
  const handleSignupError = handleAuthError(setError)

  const [signup] = useMutation(SIGNUP, {
    onError: (err) => {
      handleSignupError(err.message)
    },
  })

  const handleSignup = () => {
    const inputErr = checkEmailFormat(email)
    if (!!inputErr) {
      handleSignupError(inputErr)
    } else {
      signup({ variables: { email } })
    }
  }

  return { handleSignupInput, handleSignup, error }
}

export default useSignup
