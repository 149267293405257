import { useRef } from 'react'

import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'
import useOnClickOutside from 'src/hooks/useClickOutside'

interface Props {
  isModalOpen: boolean
  setModalOpen: (isModalOpen: boolean) => void
  children: React.ReactNode | JSX.Element
  showLogo?: boolean
  className?: string
}

const Modal = ({
  isModalOpen,
  setModalOpen,
  children,
  showLogo = true,
  className = '',
}: Props) => {
  const modalRef = useRef(null)
  const toggleModal = () => setModalOpen(!isModalOpen)
  const closeModal = () => {
    if (isModalOpen) setModalOpen(false)
    return
  }

  useOnClickOutside(modalRef, closeModal)

  return (
    <div
      className={`modal modal--${isModalOpen ? 'open' : 'closed'} ${className}`}
    >
      <div ref={modalRef} className="modal__wrapper">
        <div className="modal__header">
          <div>
            {showLogo && <Icon name={IconNames.LOGO_SMALL} size={30} />}
          </div>
          <span>
            <Icon
              name={IconNames.CROSS}
              size={20}
              customClickEvent={closeModal}
            />
          </span>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  )
}

export default Modal
