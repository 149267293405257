import { useState } from 'react'

import appUrlByEnvironment from 'src/lib/environment'
import { INRWorkout } from 'src/types/workouts'
import Icon, { IconNames } from 'src/components/blocks/icons'
import User from 'src/components/blocks/user'
import './style.scss'

type Props = Pick<INRWorkout, 'name' | 'user' | 'createdAt'> & {
  id: string
}

const WorkoutHeader = ({ name = '', user, createdAt, id }: Props) => {
  const [displayCopied, setDisplayCopied] = useState<boolean>(false)

  const appLink = appUrlByEnvironment()
  const workoutLink = `${appLink}/workout/${id}`

  const linkToClipboard = () => {
    navigator.clipboard.writeText(workoutLink)
    setDisplayCopied(true)
    setTimeout(() => {
      setDisplayCopied(false)
    }, 2000)
  }

  return (
    <div className="workoutHeader">
      <User id={user?.id} userName={user?.userName} createdAt={createdAt} />
      {displayCopied && (
        <p className="workoutHeader__copyLink">Link copied to clipboard!</p>
      )}
      <Icon
        name={IconNames.SHARE}
        size={15}
        customClickEvent={linkToClipboard}
      />
    </div>
  )
}

export default WorkoutHeader
