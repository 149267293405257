import { Link } from 'react-router-dom'

import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

const Footer = () => {
  return (
    <div className="footer">
      <Link to="/activity-feed">
        <Icon name={IconNames.ACTIVITY} size={25} />
      </Link>
      <Link to="/me">
        <Icon name={IconNames.PROFILE_ICON} size={25} />
      </Link>
      <Link to="/more">
        <Icon name={IconNames.MORE} size={15} />
      </Link>
    </div>
  )
}

export default Footer
