import { Link } from 'react-router-dom'

import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

interface Props {
  link: string
  text: string
}

const MoreSubject = ({ link, text }: Props) => {
  return (
    <div className="moreSubject">
      <Icon name={IconNames.INSTAGRAM} size={25} />
      <Link className="moreSubject__text" to={link}>
        <p>
          <b>{text}</b>
        </p>
      </Link>
      <Link to={link}>
        <Icon name={IconNames.ARROW_RIGHT} size={7.5} />
      </Link>
    </div>
  )
}

export default MoreSubject
