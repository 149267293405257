import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

interface Props {
  setDisplayPopup: () => void
}

const SignupPopup = ({ setDisplayPopup }: Props) => {
  return (
    <div className="signup-popup">
      <div className="signup-popup__head">
        <Icon name={IconNames.LOGO_SMALL} />
        <Icon name={IconNames.CROSS} customClickEvent={setDisplayPopup} />
      </div>

      <div className="signup-popup__content">
        <h1>Welcome to the NextRound Club!</h1>
        <h4>
          You will receive an email from us where you need to verify your
          account
        </h4>
        <div>You can set up your profile after that.</div>
      </div>
    </div>
  )
}

export default SignupPopup
