import { useHistory } from 'react-router-dom'

import ButtonLarge from 'src/components/blocks/button-large'
import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

const Welcome = () => {
  const history = useHistory()

  const redirectLogin = () => history.push('/login')

  const handleDiscover = () => history.push('/signup')

  return (
    <div className="welcome">
      <div className="welcome__container">
        <div className="welcome__header">
          <Icon name={IconNames.LOGO_BIG} size={200} />
          <div className="welcome__header-sub">
            Welcome to the NextRound Club!
          </div>
        </div>
        <div className="welcome__button-container">
          <ButtonLarge
            buttonType="tertiary"
            clickHandler={redirectLogin}
            children={<h2 className="login-button">Login</h2>}
          />
          <ButtonLarge
            cn="welcome__discoverBtn"
            clickHandler={handleDiscover}
            buttonType="secondary"
            children={
              <div className="discover-button">
                <div className="discover-button__title">DISCOVER NEXTROUND</div>
                <div className="discover-button__sub">
                  <p>Create an account</p>
                </div>
              </div>
            }
          />
          <a
            href="https://nextroundboxing.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="welcome__nr-link">
              <p className="small">
                <u>Go to the website of NextRound</u>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Welcome
