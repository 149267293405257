import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIsFirstRender } from 'usehooks-ts'

import RanksHeader from './ranks-header'
import SingleRank from './singe-rank'
import { LEADERBOARD_OFFSET, LEADERBOARD_USER } from 'src/graphql/queries'
import { INRLeaderboardRecordForUser } from 'src/types/workouts'

interface Props {
  leaderboardId: string
  email: string
  myId: string
}

const Ranks = ({ leaderboardId, email, myId }: Props) => {
  const client = useApolloClient()
  const isFirst = useIsFirstRender()
  const ranksListRef = useRef<HTMLDivElement | null>(null)
  const [records, setRecords] = useState<INRLeaderboardRecordForUser[]>([])
  const displayOverlay = records[0]?.offset !== 1 ?? false

  useEffect(() => {
    if (ranksListRef.current && isFirst) {
      ranksListRef.current.scrollTop =
        ranksListRef.current.scrollHeight / 2 + 40 // ~40px is the height of a single rank, aligning the center of the list
    }
  }, [records, isFirst])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await client.query({
        query: LEADERBOARD_USER,
        variables: { leaderboardId, email },
      })

      setRecords(data.getLeaderboardForUser)
    }

    fetchData()
  }, [client, leaderboardId, email])

  const handleScroll = useCallback(async () => {
    if (!ranksListRef.current) return
    const { scrollTop, scrollHeight, clientHeight } = ranksListRef.current
    const bottom = scrollTop + clientHeight >= scrollHeight - 10
    const top = scrollTop === 0

    if (top && !!records.length) {
      const firstItem = records[0]
      const offset = firstItem?.offset - 10

      if (offset < 0) return

      const { data } = await client.query({
        query: LEADERBOARD_OFFSET,
        variables: { leaderboardId, offset },
      })

      setRecords((prev) => [...data.getLeaderboardForOffset, ...prev])
    }

    if (bottom && !!records.length) {
      const lastItem = records[records.length - 1]
      const offset = lastItem?.offset + 1

      const { data } = await client.query({
        query: LEADERBOARD_OFFSET,
        variables: { leaderboardId, offset },
      })

      setRecords((prev) => [...prev, ...data.getLeaderboardForOffset])
    }
  }, [records, leaderboardId, client])

  return (
    <div className="ranks">
      <RanksHeader />
      <div
        className="ranksList"
        ref={ranksListRef}
        onScroll={handleScroll}
        data-overlay={displayOverlay}
      >
        {records.map((item: INRLeaderboardRecordForUser, i: number) => (
          <SingleRank
            key={i}
            rank={item.offset}
            score={item.leaderboardRecord.score}
            isMe={item.leaderboardRecord.user.id === myId}
            userName={item.leaderboardRecord?.user?.userName}
          />
        ))}
      </div>
    </div>
  )
}

export default Ranks
