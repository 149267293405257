import { INRWorkout } from 'src/types/workouts'
import { getWorkoutStats } from 'src/lib/stats'
import useTabsPanel from 'src/hooks/useTabsPanel'
import { toFullTime } from 'src/lib/utilities'
import TabsPanel from 'src/components/blocks/tabs-panel'
import Time from 'src/components//blocks/time'
import Line from 'src/components//blocks/line'
import Graphs from './graph'
import './style.scss'

interface Props {
  workoutData: INRWorkout
  singleRound: boolean
  index?: number
  weight: number | null
}

const Insights = ({ workoutData, singleRound, index, weight }: Props) => {
  const { duration, numOfHits } = getWorkoutStats(workoutData)
  const time = toFullTime(duration)
  const { activeTab, selectTab } = useTabsPanel()
  const tabs = [{ text: 'Force' }, { text: 'Intensity' }]

  return (
    <>
      <h5>Insights</h5>
      <TabsPanel tabs={tabs} activeIndex={activeTab} handleClick={selectTab} />
      <div>
        <div className="insights-row">
          <Time
            timer={
              <p className="small">
                <b>{time}</b>
              </p>
            }
            iconSize={10}
          />
        </div>
      </div>
      <p className="small">
        Hits: <b>{numOfHits}</b>
      </p>
      <Graphs
        workoutData={workoutData}
        isForceGraph={activeTab === 0}
        singleRound={singleRound}
        index={index}
        weight={weight}
      />
      <Line hue="normal" />
    </>
  )
}

export default Insights
