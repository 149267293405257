import { ReactElement } from 'react'

import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

interface Props {
  title?: string
  content: ReactElement
  showLogo: boolean
  displayPopup: () => void
}

const Popup = ({ title, content, showLogo, displayPopup }: Props) => {
  return (
    <div className="popup">
      <div className="popup__background">
        <div className="popup__header">
          <div>
            {showLogo && <Icon name={IconNames.LOGO_SMALL} size={30} />}
          </div>
          <div onClick={displayPopup}>
            <Icon name={IconNames.CROSS} size={20} />
          </div>
        </div>
        <h2 className="popup__title">{title}</h2>
        <div className="popup__content">{content}</div>
      </div>
    </div>
  )
}

export default Popup
