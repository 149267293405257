import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { UPDATE_USER } from 'src/graphql/mutations'
import { ME } from 'src/graphql/queries'
import { Gender, INRMe, INRUser, PrivacyLevel } from 'src/types/user'
import { handleAuthError, handleInput } from './helpers'

const useEditProfile = () => {
  const { data, loading } = useQuery<INRMe>(ME)

  const [inputValues, setInputValues] = useState<INRUser | null>(null)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)

  const handleEditError = handleAuthError(setError)
  const handleEditInput = handleInput(setInputValues)

  useEffect(() => {
    if (!loading && data) {
      const initProfileState: INRUser = {
        id: data.me.id || '',
        userName: data.me.userName || '',
        email: data.me.email || '',
        gender: data.me.gender || Gender.Neutral,
        weightKg: data.me.weightKg || 0,
        privacyLevel: data.me.privacyLevel || PrivacyLevel.Public,
      }
      setInputValues(initProfileState)
    }
  }, [data, loading])

  const [editProfile] = useMutation(UPDATE_USER, {
    onError: (err) => {
      handleEditError(err.message)
    },
    onCompleted: () => {
      setSuccess(true)
    },
  })

  const submitEditProfile = () => {
    const filteredInputValues = Object.fromEntries(
      Object.entries(inputValues || {}).filter(([key, value]) => value)
    )

    editProfile({
      variables: { ...filteredInputValues },
    })
  }

  return { handleEditInput, submitEditProfile, error, success }
}

export default useEditProfile
