import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { INRWorkout } from 'src/types/workouts'
import { UPDATE_WORKOUT_NOTES } from 'src/graphql/mutations'
import Button from 'src/components/blocks/button'
import Input from 'src/components/blocks/input'
import Modal from 'src/components/blocks/modal'
import './style.scss'

type Props = Pick<INRWorkout, 'id' | 'notes'> & {
  setDisplayNote: (displayNote: boolean) => void
  displayNote: boolean
}

const NotePopup = ({ id, notes, setDisplayNote, displayNote }: Props) => {
  const [noteText, setNoteText] = useState<string>('')
  const [updateWorkout] = useMutation(UPDATE_WORKOUT_NOTES)

  const updateNotes = () => {
    updateWorkout({
      variables: {
        id,
        notes: noteText,
      },
    }).then(() => {
      setDisplayNote(false)
      window.location.reload()
    })
  }

  return (
    <Modal isModalOpen={displayNote} setModalOpen={setDisplayNote}>
      <div className="noteModal">
        <h1 className="light">Edit Notes</h1>
        <p className="small bold">Notes</p>
        <Input
          inputType="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setNoteText(e.target.value)
          }}
          placeholder={!!notes ? notes : 'Add a note'}
        />
        <Button clickHandler={updateNotes} children="Save" />
      </div>
    </Modal>
  )
}

export default NotePopup
