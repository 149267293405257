import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import Input from 'src/components/blocks/input'
import Icon, { IconNames } from 'src/components/blocks/icons'
import ButtonLarge from 'src/components/blocks/button-large'
import { FORGOT_PASSWORD } from 'src/graphql/mutations'
import './style.scss'

const ForgotPassword = () => {
  const history = useHistory()
  const [feedback, setFeedback] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const navigateToHome = () => {
    history.push('/')
  }

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onError: (err) => {
      setError(err.message)
    },
    onCompleted: () => {
      setFeedback(
        'An email has been sent to you with a link to reset your password.'
      )
    },
  })

  const handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value)
  }

  const handleSubmit = () => {
    return forgotPassword({ variables: { email } })
  }

  return (
    <>
      <div className="forgotPassword">
        <div className="forgotPassword__header">
          <Icon name={IconNames.LOGO_SMALL} size={30} />
          <Icon
            name={IconNames.CROSS}
            size={20}
            customClickEvent={navigateToHome}
          />
        </div>
        <div className="forgotPassword__body">
          <h2>Forgot your password?</h2>
          <h4>No problem! Enter your e-mail below and set up a new one.</h4>
          <Input
            name="email"
            inputType="email"
            spacingTop
            placeholder="email"
            onChange={handleInput}
          />

          <div style={{ pointerEvents: feedback ? 'none' : 'auto' }}>
            <ButtonLarge
              buttonType="tertiary"
              children={<h2>Confirm</h2>}
              clickHandler={handleSubmit}
            />
          </div>
          {error && <div className="forgotPassword__error">{error}</div>}
          {feedback && (
            <div className="forgotPassword__feedback">{feedback}</div>
          )}
          <div className="forgotPassword__help">
            Doesn't work?{' '}
            <Link
              target="_blank"
              to={{ pathname: 'https://nextroundboxing.com/contact/' }}
            >
              Click here for help
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
