import { useQuery } from '@apollo/client'

import LeaderBoardsBanner from './banner'
import Ranks from './ranks'
import { ME } from 'src/graphql/queries'
import { INRMe, PrivacyLevel } from 'src/types/user'
import NoPermission from './no-permission'
import './style.scss'
import Loader from 'src/components/blocks/loader'
import Error from 'src/components/blocks/error'

interface Props {
  leaderboardId: string
}

const LeaderBoards = ({ leaderboardId }: Props) => {
  const { data, loading, error } = useQuery<INRMe>(ME)

  if (loading) return <Loader />
  if (error)
    return <Error>{error?.message ?? 'Error fetching leaderboards'}</Error>

  const isPublic = data?.me?.privacyLevel === PrivacyLevel.Public
  const email = data?.me?.email ?? ''
  const myId = data?.me?.id ?? ''

  if (!isPublic) return <NoPermission />

  return (
    <div className="leaderBoards">
      <LeaderBoardsBanner leaderboardId={leaderboardId} />
      <Ranks email={email} myId={myId} leaderboardId={leaderboardId} />
    </div>
  )
}

export default LeaderBoards
