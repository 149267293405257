import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar } from 'recharts'

import { INRRound } from 'src/types/workouts'
import { getForceGraphData, getOldRoundBarsData, getRoundBarsData } from 'src/lib/graph'
import './style.scss'
import { workoutIsBeforeJune2024 } from 'src/lib/utilities'

interface Props {
  roundData: INRRound
  maxForce: number
  workoutCreatedAt: string
}

const ForceGraphRound = ({ roundData, maxForce, workoutCreatedAt }: Props) => {
  const isBefore = workoutIsBeforeJune2024(workoutCreatedAt)
  const barsArray = isBefore ? getOldRoundBarsData(roundData) : getRoundBarsData(roundData)
  const forceGraphData = getForceGraphData(barsArray)

  return (
    <ResponsiveContainer width={300} height="100%">
      <BarChart data={forceGraphData}>
        <XAxis
          tick={false}
          dataKey="index"
          type="number"
          domain={[0, forceGraphData.length]}
        />
        <YAxis domain={[0, maxForce]} tick={false} hide={true} />
        <Bar
          dataKey="force"
          fill="#00BFB9"
          radius={[10, 10, 0, 0]}
          maxBarSize={20}
          barSize={5}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ForceGraphRound
