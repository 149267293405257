interface Props {
  rank: number
  userName?: string | null
  score: number
  isMe?: boolean
}

const SingleRank = ({ rank, score, userName, isMe = false }: Props) => {
  const boldUserName = isMe ? 'bold' : ''

  return (
    <div className="singleRank" data-me={isMe}>
      <p className="bold">{rank}</p>
      <p className={boldUserName}>{userName ?? 'Anonymous'}</p>
      <p className="bold">{Math.round(score)}</p>
    </div>
  )
}

export default SingleRank
