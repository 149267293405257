import './style.scss'

export interface Tab {
  text: string
  isActive: boolean
  isNew?: boolean
  handleClick: () => void
}

const SingleTab = ({ text, isActive, isNew = false, handleClick }: Tab) => (
  <p
    className="singleTab"
    data-active={isActive}
    data-new={isNew}
    onClick={handleClick}
  >
    {text}
  </p>
)

export default SingleTab
