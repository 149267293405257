import FaqCategory from 'src/components/blocks/faq-category'
import { questionData } from './constants'
import './style.scss'

const Faq = () => {
  return (
    <div className="faq">
      <p>What do you want to know?</p>
      {questionData.map(({ title, questions }, i) => {
        return <FaqCategory key={i} title={title} questions={questions} />
      })}
    </div>
  )
}

export default Faq
