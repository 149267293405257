import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { IdQuery } from 'src/types/queries'
import { MY_ID } from 'src/graphql/queries'
import './style.scss'

interface Props {
  image: any
  name: string
  id: string
}

const SingleBuddy = ({ image, name, id }: Props) => {
  const { data: meData } = useQuery<IdQuery>(MY_ID)
  const myId = meData?.me.id
  if (id === myId) {
    return (
      <div className="single-buddy">
        <div className="single-buddy__image">{image}</div>
        <p>
          <b>{name}</b>
        </p>
      </div>
    )
  } else {
    return (
      <Link to={`/profile/${id}`}>
        <div className="single-buddy">
          <div className="single-buddy__image">{image}</div>
          <p>
            <b>{name}</b>
          </p>
        </div>
      </Link>
    )
  }
}

export default SingleBuddy
