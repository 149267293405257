import { useHistory } from 'react-router-dom'

import Modal from 'src/components/blocks/modal'
import Button from 'src/components/blocks/button'
import './style.scss'

interface Props {
  display: boolean
  setDisplay: (display: boolean) => void
}

const ResetPasswordPopup = ({ display, setDisplay }: Props) => {
  const history = useHistory()

  const redirectLogin = () => {
    history.push('/login')
  }

  return (
    <Modal
      className="resetPwPopup"
      isModalOpen={display}
      setModalOpen={setDisplay}
    >
      <h2>Password confirmed</h2>
      <div>Login with your new password</div>
      <Button clickHandler={redirectLogin}>Go to login</Button>
    </Modal>
  )
}

export default ResetPasswordPopup
