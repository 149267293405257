import { ReactNode } from 'react'
import './style.scss'

interface Props {
  className?: string
  children: ReactNode
}

const Label = ({ className = '', children }: Props) => (
  <div className={`${className} label`}>{children}</div>
)

export default Label
