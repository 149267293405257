import { useQuery } from '@apollo/client'

import { Follow } from 'src/types/user'
import useTabsPanel from 'src/hooks/useTabsPanel'
import { MY_ID_AND_FOLLOWS } from 'src/graphql/queries'
import { IdAndFollowsQuery } from 'src/types/queries'
import SingleBuddy from 'src/components/blocks/single-buddy'
import RequestInviteButton from 'src/components/blocks/request-invite-button'
import TabsPanel from 'src/components/blocks/tabs-panel'
import Icon, { IconNames } from 'src/components/blocks/icons'
import './style.scss'

// This page/feature is currently taken out
// The buddies feature is not implemented yet
const Buddies = () => {
  const { data, loading, error } =
    useQuery<IdAndFollowsQuery>(MY_ID_AND_FOLLOWS)
  const followers = data?.me?.followers
  const following = data?.me?.following

  const image = <Icon name={IconNames.PROFILE_PIC} size={20} />

  const tabs = [{ text: 'Following' }, { text: 'Followers' }]
  const { activeTab, selectTab } = useTabsPanel()

  const selectList = (
    activeTab: number,
    followers: Follow[] = [],
    following: Follow[] = []
  ) => {
    if (activeTab === 0) {
      return followers?.map((user: any) => {
        return { name: user.to.userName, id: user.to.id }
      })
    } else {
      return following?.map((user: any) => {
        return { name: user.from.userName, id: user.from.id }
      })
    }
  }

  const selected = selectList(activeTab, followers, following)

  const renderBuddies =
    !!selected &&
    selected.map(({ id, name }: any, i: number) => {
      return <SingleBuddy key={i} image={image} name={name} id={id} />
    })

  return (
    <div className="buddies">
      <RequestInviteButton
        amount="0"
        link="/follow-requests"
        text="Follow Requests"
      />
      <RequestInviteButton amount="+" link="/invite" text="Invite" />
      <TabsPanel tabs={tabs} activeIndex={activeTab} handleClick={selectTab} />
      <div className="buddies__req-invite">{renderBuddies}</div>
    </div>
  )
}

export default Buddies
