import Icon, { IconNames } from 'src/components/blocks/icons'
import Label from 'src/components/blocks/label'

interface Props {
  iconName: IconNames
  rank: number
  userName?: string | null
  score: number
}

const TopThreeBadge = ({ rank, iconName, userName = '', score }: Props) => {
  const iconSize = rank === 1 ? 60 : 42
  const boldUserName = rank === 1 ? 'bold' : ''

  return (
    <div className="topThreeBadge" data-rank={rank}>
      <div className="topThreeBadge__iconWrapper">
        <Icon name={iconName} size={iconSize} />
        <div className="topThreeBadge__rank">{rank}</div>
      </div>
      <p className={`topThreeBadge__userName ${boldUserName}`}>{userName}</p>
      <Label>{Math.round(score)}</Label>
    </div>
  )
}

export default TopThreeBadge
