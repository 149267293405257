import './style.scss'

interface Props {
  name: string
  options: { value: string; text: string }[]
  handleInput: (evt: any) => void
}

const Select = ({ name, options, handleInput }: Props) => {
  return (
    <select name={name} onChange={(evt) => handleInput(evt)}>
      {options.map(({ value, text }, i) => (
        <option key={i} value={value}>
          {text}
        </option>
      ))}
    </select>
  )
}

export default Select
