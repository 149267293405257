import { INRWorkout } from 'src/types/workouts'
import { getAllWorkoutsStats } from 'src/lib/stats'
import Line from 'src/components/blocks/line'
import './style.scss'

interface Props {
  workouts?: INRWorkout[]
}

const ProfileCard = ({ workouts = [] }: Props) => {
  // TODO get stats from the backend, or if that's unavailable from lib/stats functions
  const numOfHits = workouts.reduce(
    (acc, workout) => acc + workout.numOfHits,
    0
  )
  const duration = workouts.reduce((acc, workout) => acc + workout.duration, 0)
  const maxForce = Math.round(
    Math.max(...workouts.map((workout) => workout.maxForce))
  )
  const maxSpeed = Math.max(...workouts.map((workout) => workout.maxSpeed))
  const hours = String(Math.floor(duration / 3600)).padStart(2, '0')

  return (
    <div className="profileCard">
      <div className="profileCard__top">
        <p className="large dark">
          Total <br /> workouts
        </p>
        <p className="large dark">
          Total <br />
          punches
        </p>
        <p className="large dark">
          Total <br /> hours
        </p>
        <p className="large bold">{workouts.length}</p>
        <p className="large bold">{numOfHits}</p>
        <p className="large bold">{hours}</p>
      </div>
      <Line hue="dark" />
      <div className="profileCard__bottom">
        <p className="large dark">Highest force</p>
        <p className="large dark">Highest speed</p>
        <p className="large bold">{maxForce} kg</p>
        <p className="large bold">{maxSpeed} hits/m</p>
      </div>
    </div>
  )
}

export default ProfileCard
